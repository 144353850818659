import { createAsyncThunk } from '@reduxjs/toolkit';
import { userService } from 'services';

export const createUser = createAsyncThunk(
  'user/createUser',
  async (userInfo, { rejectWithValue }) => {
    try {
      const { data } = await userService.createUser(userInfo);
      return data.message;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const updateUser = createAsyncThunk(
  'user/updateUser',
  async (userInfo, { rejectWithValue }) => {
    try {
      const { data } = await userService.updateUser(userInfo);
      return data.message;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const forgetPassword = createAsyncThunk(
  'user/forgetPassword',
  async (email, { rejectWithValue }) => {
    try {
      const { data } = await userService.forgetPassword(email);
      return data.message;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const resetPassword = createAsyncThunk(
  'user/resetPassword',
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await userService.resetPassword(values);
      return data.message;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const createFinancialData = createAsyncThunk(
  'user/createFinancialData',
  async (financialData, { rejectWithValue }) => {
    try {
      const { data } = await userService.createFinancialData(financialData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getFinancialData = createAsyncThunk(
  'user/getFinancialData',
  async (_params, { rejectWithValue }) => {
    try {
      const { data } = await userService.getFinancialStatus();
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getContentReport = createAsyncThunk(
  'user/getContentReport',
  async (contentId, { rejectWithValue }) => {
    try {
      const { data } = await userService.getContentReport(contentId);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);
export const getComedianContentReport = createAsyncThunk(
  'user/getComedianContentReport',
  async (contentId, { rejectWithValue }) => {
    try {
      const { data } = await userService.getComedianContentReport(contentId);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);
export const updateFinancialData = createAsyncThunk(
  'user/updateFinancialData',
  async (financialData, { rejectWithValue }) => {
    try {
      const { data } = await userService.updateFinancialData(financialData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const changePassword = createAsyncThunk(
  'user/changePassword',
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await userService.changePassword(values);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getUserContent = createAsyncThunk(
  'user/contents',
  async (paginationData, { rejectWithValue }) => {
    try {
      const { data } = await userService.getUserContent(paginationData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);
export const getAccountantContents = createAsyncThunk(
  'user/accountantContents',
  async (paginationData, { rejectWithValue }) => {
    try {
      const { data } = await userService.getAccountantContents(paginationData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getUserPayouts = createAsyncThunk(
  'user/payouts',
  async (_params, { rejectWithValue }) => {
    try {
      const { data } = await userService.getUserPayouts();
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const askForPayout = createAsyncThunk(
  'user/askForPayout',
  async (_params, { rejectWithValue }) => {
    try {
      const { data } = await userService.askForPayout();
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);
export const cancelPayout = createAsyncThunk(
  'user/cancelPayout',
  async (_params, { rejectWithValue }) => {
    try {
      const { data } = await userService.cancelPayout();
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);
export const getStatistics = createAsyncThunk(
  'user/getStatistics',
  async (_params, { rejectWithValue }) => {
    try {
      const { data } = await userService.getStatistics();
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);
export const getCurentWatched = createAsyncThunk(
  'user/getCurentWatched',
  async (contentId, { rejectWithValue }) => {
    try {
      const { data } = await userService.getCurentWatchedTime(contentId);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);
export const getCurentWatchedComedian = createAsyncThunk(
  'user/getCurentWatchedComedian',
  async (contentId, { rejectWithValue }) => {
    try {
      const { data } = await userService.getCurentWatchedTimeComedian(contentId);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);
export const choosepaymentmethod = createAsyncThunk(
  'user/choosepaymentmethod',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await userService.choosepaymentmethod(payload);
      return { message: data.message, payload };
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getNews = createAsyncThunk(
  'user/news',
  async (_params, { rejectWithValue }) => {
    try {
      const { data } = await userService.getNews();
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);
export const getComedianList = createAsyncThunk(
  'user/comedianList',
  async (_params, { rejectWithValue }) => {
    try {
      const { data } = await userService.getComedianList();
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const addComedian = createAsyncThunk(
  'user/AddComedian',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await userService.AddComedian(payload);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const uploadContentByComedian = createAsyncThunk(
  'user/uploadContentByComedian',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await userService.uploadContentByComedian(payload);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const askForAccountantPayout = createAsyncThunk(
  'user/askForAccountantPayout',
  async (_params, { rejectWithValue }) => {
    try {
      const { data } = await userService.askForAccountantPayout();
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getAccountantPayouts = createAsyncThunk(
  'user/getAccountantPayouts',
  async (_params, { rejectWithValue }) => {
    try {
      const { data } = await userService.getAccountantPayouts();
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const cancelAccountantPayout = createAsyncThunk(
  'user/cancelAccountantPayout',
  async (_params, { rejectWithValue }) => {
    try {
      const { data } = await userService.cancelAccountantPayout();
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const changeUserContentVatStatus = createAsyncThunk(
  'user/changeUserContentVatStatus',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await userService.changeUserContentVatStatus(payload);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

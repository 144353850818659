/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';

import { useAppDispatch } from 'hooks';
import { ComponentLayout } from 'layouts';
import {
  AutoComplete,
  Form,
  Button,
  Input,
  PlusOutlined,
  Space,
} from 'components/AntDesign';
import {
  getMatchedContent,
} from 'store/admin/thunk';

import { MinusCircleOutlined } from '@ant-design/icons';
import { addOtherRevenue } from 'store/content/thunk';

function AddOtherRevenue() {
  const [value, setValue] = useState('');
  const [contents, setContents] = useState({});
  const dispatch = useAppDispatch();
  const contentsOptions = Object.values(contents).map((option) => ({
    value: option.title,
    id: option.id,
  }));
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await dispatch(getMatchedContent()).unwrap();
      setContents(data);
    };
    fetchData();
  }, []);

  const onFinish = async (values) => {
    const contentId = contents.find((content) => content.title === value).id;
    const modifiedValues = {
      contentId,
      otherRevenue: values.otherRevenue?.map(({ service, revenue }) => ({
        service,
        revenue:
        +revenue,
      })),
    };
    await dispatch(addOtherRevenue(modifiedValues));
  };

  const onChange = (text) => {
    setValue(text);
  };

  const filterOption = {
    filterOption:
    (inputValue, option) => option?.value?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1,
  };

  return (
    <ComponentLayout title="Deals Page" heading="Add Other Revenue">
      <Form
        name="match-content-with-user"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        autoComplete="off"
        className="my-form"
        layout="vertical"
        requiredMark={false}
      >
        <Form.Item label="The Content Name" name="id">
          <AutoComplete
            size="large"
            value={value}
            options={contentsOptions}
            onChange={onChange}
            placeholder="Search content name"
            filterOption={filterOption}
          />
        </Form.Item>
        <Form.List name="otherRevenue">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{
                    display: 'flex',
                    marginBottom: 8,
                  }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'service']}
                    rules={[
                      {
                        required: true,
                        message: 'Missing service name',
                      },
                    ]}
                  >
                    <Input placeholder="Service Name" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'revenue']}
                    rules={[
                      {
                        required: true,
                        message: 'Missing revenue',
                      },
                    ]}
                  >
                    <Input placeholder="Revenue" type="number" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add field
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button type="primary" htmlType="submit" size="large" className="form-button">
            Update Content
          </Button>
        </Form.Item>
      </Form>
    </ComponentLayout>
  );
}

export default AddOtherRevenue;

import { useAppDispatch } from 'hooks';
import {
  Input, Button, Form,
} from 'components/AntDesign';

import { ComponentLayout } from 'layouts';
import { addNewContent } from 'store/admin/thunk';
import { validationMessages } from 'utils';

export default function AddNewContentPage() {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const onFinish = async (values) => {
    dispatch(addNewContent(values));
    form.resetFields();
  };

  return (
    <ComponentLayout title="Add Content" heading="Add New Content">
      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
      >
        <div className="add-new-user-form-form-f">
          <Form.Item
            name="id"
          >
            <Input placeholder="ID" />
          </Form.Item>
          <Form.Item
            name="title"
            rules={[
              {
                required: true,
                message: validationMessages.required,
              },
            ]}
          >
            <Input placeholder="Title" />
          </Form.Item>

          <Form.Item noStyle>
            <Button className="form-button" type="primary" htmlType="submit">
              Add Content
            </Button>
          </Form.Item>
        </div>
      </Form>
    </ComponentLayout>
  );
}

import { Link } from 'react-router-dom';
import { Logo } from 'shared/components';
import './style.css';
import { Alert } from 'components/AntDesign';

function Header() {
  return (
    <div className="container">
      <Alert
        description="The preferred browser for this site is Google Chrome. Please use this browser for the best experience."
        type="warning"
        showIcon
        closable
      />
      <div>
        <nav className="landing-navbar  navbar-expand-lg navbar-dark fixed-top">
          <div className="logolanding flex">
            <Link to="/login">
              <Logo className="" />
            </Link>
          </div>
          <div className="signin flex">
            <Link to="/login">
              <button type="button" className="btn btn-primary">
                Sign In
              </button>
            </Link>
          </div>
        </nav>

        <div className="main-text flex">
          <div className="sec-1">
            <p>NextUp Portal for Acts, Agents and Venues</p>
          </div>
          <div className="flex">
            <Link to="/signup">
              <button type="button" className="btn-get-started">
                Get started
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;

import { useEffect, useState } from 'react';

import { GeneralView, DetailedView } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getStatistics } from 'store/user/thunk';
import { Loader } from 'shared/components';

function Statistics() {
  const dispatch = useAppDispatch();
  const [theData, setData] = useState({});
  const [rows, setRows] = useState([]);

  const { user, isLoading } = useAppSelector((state) => state.checkAuth);

  useEffect(() => {
    if (user.id) {
      const fetchData = async () => {
        const data = await dispatch(getStatistics()).unwrap();
        setData(data.data);
        setRows(data.data?.content.rows);
      };
      fetchData();
    }
  }, []);

  // const filteredRows = rows.filter((row) => {
  //   const today = new Date();
  //   const launchDate = new Date(row.launchDate);
  //   const diffTime = Math.abs(today - launchDate);
  //   const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  //   return diffDays <= 90;
  // });
  // i want to filter based on contentReports if it has watchedseconds > 0
  const filteredRows = rows
    .filter((row) => row.contentReports.some((report) => report.watchedSeconds > 0));

  const earning = Number(theData?.totalRevenue).toFixed(2);
  const payout = Number(theData?.paidRevenue).toFixed(2);
  const count = theData.content?.count;
  const balance = Number(theData?.balance).toFixed(2);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <GeneralView count={count} balance={Number(balance)} earning={+earning} payout={+payout} />
      <DetailedView rows={filteredRows} />
    </>
  );
}

export default Statistics;

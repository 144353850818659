import React, { useEffect, useState } from 'react';
import {
  Table, Switch, Button,
} from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getUserContent, changeUserContentVatStatus } from 'store/user/thunk';
import './style.css';

function VideoList() {
  const dispatch = useAppDispatch();
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updatedVideos, setUpdatedVideos] = useState([]);
  const { user } = useAppSelector((state) => state.checkAuth);

  useEffect(() => {
    if (user.id) {
      const fetchUserContent = async () => {
        setLoading(true);
        const response = await dispatch(getUserContent({ page: 1, limit: 100 })).unwrap();
        setVideos(response.data?.rows || []);
        setLoading(false);
      };
      fetchUserContent();
    }
  }, [user.id, dispatch]);

  const handleVatChange = (contentId, status) => {
    setVideos((prevVideos) => prevVideos.map((video) => (video
      .id === contentId ? { ...video, vat: status } : video)));

    setUpdatedVideos((prevUpdatedVideos) => {
      const existingIndex = prevUpdatedVideos.findIndex(
        (video) => video.contentId === contentId,
      );
      const updatedVideo = { contentId, status };
      if (existingIndex >= 0) {
        return prevUpdatedVideos.map((
          video,
          index,
        ) => (index === existingIndex ? updatedVideo : video));
      }
      return [...prevUpdatedVideos, updatedVideo];
    });
  };

  const handleSaveChanges = async () => {
    try {
      await dispatch(
        changeUserContentVatStatus({
          userId: user.id,
          contentStatusArray: updatedVideos,
        }),
      ).unwrap();
      setUpdatedVideos([]);
    } catch (error) {
      console.error('Error updating VAT status:', error);
    }
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'VAT Status',
      dataIndex: 'vat',
      key: 'vat',
      render: (text) => (text ? 'Yes' : 'No'),
    },
    {
      title: 'Change VAT Status',
      key: 'changeVatStatus',
      render: (_, record) => (
        <Switch
          checked={record.vat}
          onChange={(checked) => handleVatChange(record.id, checked)}
          handleBg="#0eb6ad"
        />
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={videos}
        loading={loading}
        rowKey="id"
        pagination={{ pageSize: 10 }}
      />
      <Button
        type="primary"
        className="form-button"
        onClick={handleSaveChanges}
        disabled={updatedVideos.length === 0}
        style={{ marginTop: '16px' }}
      >
        Save Changes
      </Button>
    </>
  );
}

export default VideoList;

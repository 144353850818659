import { useEffect, useState } from 'react';
import humanizeDuration from 'humanize-duration';
import { Link, useParams } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import { GeneralView } from 'components';
import { getUserStatistics } from 'store/admin/thunk';
import CurrentWatchedSeconds from 'components/Dashboard/Statistics/DetailedView/CurrentWatchedSeconds';
import OtherRevuneModal from 'components/Dashboard/Statistics/DetailedView/OtherRevuneModal';
import { Alert, Button, Table } from 'components/AntDesign';
import { ComponentLayout } from 'layouts';
import {
  adminUsersStatisticsColumns,
} from 'shared/objects/Users';

function UserStatisticsPage() {
  const dispatch = useAppDispatch();
  const { userId } = useParams();
  const [data, setData] = useState({});
  const [rows, setRows] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const { payload } = await dispatch(getUserStatistics(userId));
      setData(payload.data);
      setRows(payload.data.content.rows);
    };
    fetchData();
  }, [userId]);
  const balance = data?.balance;
  const earning = data?.totalRevenue;
  const payout = data?.paidRevenue;
  return (
    <>
      <GeneralView
        count={data?.content?.count}
        balance={Math.round(balance * 100) / 100}
        earning={Math.round(earning * 100) / 100}
        payout={Math.round(payout * 100) / 100}
      />
      <ComponentLayout heading="Detailed View For Previous Quarter only">
        <Table
          columns={adminUsersStatisticsColumns}
          dataSource={rows.map((row) => ({
            title: row.title,
            watchedSeconds: humanizeDuration(
              row.contentReports[0].watchedSeconds * 1000,
              { units: ['h', 'm', 's'] },
            ),
            owedRevenue: `£${row.contentReports[0].owedRevenue.slice(
              0,
              5,
            )} `,
            owedAccRevenue: `£${row.owedAccRevenue.slice(0, 5)}`,
            details: (
              <OtherRevuneModal
                otherRevenue={row?.contentReports[0]?.otherRevenue}
                contentReports={row?.contentReports}
                content={row}
              />
            ),
            currentWatchedSeconds: <CurrentWatchedSeconds content={row} />,
            notes: row.notes || '-',
            key: row.title,
            launchDate: new Date(row.launchDate),
            reports: (
              <Button type="primary" className="form-button">
                <Link to={`/dashboard/yourvideos/${row.id}`}>
                  View Reports
                </Link>
              </Button>
            ),

          }))
            .sort((a, b) => new Date(a.launchDate) - new Date(b.launchDate))}
          pagination={{ pageSize: 5 }}
          scroll={{ x: 500 }}
        />
        <Alert message="If you can't find the content you are looking for, please check the Home - Video List." type="info" showIcon />
      </ComponentLayout>
      {' '}

    </>
  );
}

export default UserStatisticsPage;

import { AddStripeAccount } from 'components';

function AddStripeUserPage() {
  return (
    <AddStripeAccount />

  );
}

export default AddStripeUserPage;

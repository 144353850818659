import { ServicesList } from 'components';
import { ProtectLogin } from 'middleware';
import {
  RegisterPage,
  LoginPage,
  TermsPage,
  ForgetPasswordPage,
  VideoListPage,
  PayoutPage,
  DashboardSettingsPage,
  ChangePasswordPage,
  EditProfilePage,
  ResetPasswordPage,
  NewUsersPage,
  UserListPage,
  VerifyEmailPage,
  RejectedUsersPage,
  BannedUsersPage,
  AddNewUserPage,
  ContentListPage,
  StatisticsPage,
  EditUserDataPage,
  UserStatisticsPage,
  AdminStatisticsPage,
  AdminPayoutPage,
  AddStripeUserPage,
  RepoetPage,
  ChangeContentPage,
  EditContentPage,
  PaymentMethodPage,
  VideoHistoryPage,
  AddOtherRevenuePage,
  ComedianStatisticsPage,
  AddNewsPage,
  NewsListPage,
  AddNewComedianPage,
  ComedianListPage,
  LinkContentPage,
  UploadContentPage,
  AddNewContentPage,
  NewsManagementPage,
  EditNewsPage,
  ComedianVideoListPage,
  ComedianVideoHistoryPage,
  AccountantPayoutPage,
  AccountantStatisticsPage,
  AccountantVideoListPage,
  ChangeVATStatusPage,
} from 'pages';
import { UserVatTablePage } from 'pages/UserPages';

function ProtectedLoginPage() {
  return (
    <ProtectLogin>
      <LoginPage />
    </ProtectLogin>
  );
}

const routes = [
  {
    path: 'signup',
    Component: RegisterPage,
    title: 'Sign Up',
    key: 'signup',
    pro: true,

  },
  {
    path: 'login',
    Component: ProtectedLoginPage,
    title: 'Log In',
    key: 'login',
    pro: true,
  },
  {
    path: 'forgetPassword',
    Component: ForgetPasswordPage,
    title: 'Forget Password',
    key: 'forgetPassword',
  },
  {
    path: 'resetPassword',
    Component: ResetPasswordPage,
    title: 'Reset Password',
    key: 'resetPassword',
  },
  {
    path: 'terms',
    Component: TermsPage,
    title: 'Terms & Conditions',
    key: 'terms',
  },
  {
    path: 'verifyEmail',
    Component: VerifyEmailPage,
    title: 'Verify Email',
    key: 'verifyEmail',
  },
];

const adminRoutes = [

  {
    title: 'AdminStatistics',
    path: '/dashboard/statistics',
    Component: AdminStatisticsPage,
    key: 'AdminStatistics',
  },
  {
    title: 'UserStatistics',
    path: '/dashboard/userlist/statistics/:userId',
    Component: UserStatisticsPage,
    key: 'UserStatistics',
  },
  {
    title: 'editProfile',
    path: '/dashboard/editProfile',
    Component: EditProfilePage,
    key: 'editProfile',
  },
  {
    title: 'changePassword',
    path: '/dashboard/changePassword',
    Component: ChangePasswordPage,
    key: 'changePassword',
  },
  {
    title: 'adminPayout',
    path: '/dashboard/adminpayout',
    Component: AdminPayoutPage,
    key: 'adminPayout',
  },
  {
    title: 'newUsers',
    path: '/dashboard/newUsers',
    Component: NewUsersPage,
    key: 'newUsers',
  },
  {
    title: 'userList',
    path: '/dashboard/userList',
    Component: UserListPage,
    key: 'userList',
  },
  {
    title: 'rejectedUsers',
    path: '/dashboard/rejectedUsers',
    Component: RejectedUsersPage,
    key: 'rejectedUsers',
  },
  {
    title: 'bannedUsers',
    path: '/dashboard/bannedUsers',
    Component: BannedUsersPage,
    key: 'bannedUsers',
  },
  {
    title: 'contentList',
    path: '/dashboard/contentList',
    Component: ContentListPage,
    key: 'contentList',
  },
  {
    title: 'matchContent',
    path: '/dashboard/contentList/matchContent/:contentId',
    Component: ContentListPage,
    key: 'matchContent',
  },
  {
    title: 'editUser',
    path: '/dashboard/userList/editUser/:userId',
    Component: EditUserDataPage,
    key: 'editUser',
  },
  {
    title: 'addStripeAccount',
    path: '/dashboard/addStripeAccount',
    Component: AddStripeUserPage,
    key: 'addStripeAccount',
  },
  {
    title: 'CreateRepoet',
    path: '/dashboard//reports',
    Component: RepoetPage,
    key: 'CreateRepoet',
  },
  {
    title: 'ChangeContent',
    path: '/dashboard//ChangeContent/',
    Component: ChangeContentPage,
    key: 'ChangeContent',
  },
  {
    title: 'editContent',
    path: '/dashboard/contentList/edit-content-data/:contentId',
    Component: EditContentPage,
    key: 'editContent',
  },
  {
    title: 'addOtherRevenue',
    path: '/dashboard/add-other-revenue',
    Component: AddOtherRevenuePage,
    key: 'addOtherRevenue',
  },
  {
    title: 'recentUpdates',
    path: '/dashboard/recentUpdates',
    Component: NewsListPage,
  },
  {
    title: 'addnews',
    path: '/dashboard/addnews',
    Component: AddNewsPage,
  },
  {
    title: 'newsManagement',
    path: '/dashboard/newsManagement',
    Component: NewsManagementPage,
  },
  {
    title: 'editNews',
    path: '/dashboard//editNews/:newsId',
    Component: EditNewsPage,
    key: 'editNews',
  },
  {
    title: 'addnewcontent',
    path: '/dashboard/addnewcontent',
    Component: AddNewContentPage,
  },
  {
    title: 'getServices',
    path: '/dashboard/users-uploads',
    Component: ServicesList,
  },
  {
    title: 'yourVideos:videoId',
    path: '/dashboard/yourVideos/:videoId',
    Component: VideoHistoryPage,
  },
  {
    title: 'Change VAT Status',
    path: '/dashboard/changeVATStatus',
    Component: ChangeVATStatusPage,
  },
];

const masterAdminRoutes = [

  {
    title: 'AdminStatistics',
    path: '/dashboard/statistics',
    Component: AdminStatisticsPage,
    key: 'AdminStatistics',
  },
  {
    title: 'UserStatistics',
    path: '/dashboard/userlist/statistics/:userId',
    Component: UserStatisticsPage,
    key: 'UserStatistics',
  },
  {
    title: 'editProfile',
    path: '/dashboard/editProfile',
    Component: EditProfilePage,
    key: 'editProfile',
  },
  {
    title: 'changePassword',
    path: '/dashboard/changePassword',
    Component: ChangePasswordPage,
    key: 'changePassword',
  },
  {
    title: 'Settings',
    path: '/dashboard/Settings',
    Component: DashboardSettingsPage,
    key: 'Settings',
  },
  {
    title: 'adminPayout',
    path: '/dashboard/adminpayout',
    Component: AdminPayoutPage,
    key: 'adminPayout',
  },
  {
    title: 'newUsers',
    path: '/dashboard/newUsers',
    Component: NewUsersPage,
    key: 'newUsers',
  },
  {
    title: 'userList',
    path: '/dashboard/userList',
    Component: UserListPage,
    key: 'userList',
  },
  {
    title: 'rejectedUsers',
    path: '/dashboard/rejectedUsers',
    Component: RejectedUsersPage,
    key: 'rejectedUsers',
  },
  {
    title: 'bannedUsers',
    path: '/dashboard/bannedUsers',
    Component: BannedUsersPage,
    key: 'bannedUsers',
  },
  {
    title: 'addNewUser',
    path: '/dashboard/addNewUser',
    Component: AddNewUserPage,
    key: 'addNewUser',
  },
  {
    title: 'contentList',
    path: '/dashboard/contentList',
    Component: ContentListPage,
    key: 'contentList',
  },
  {
    title: 'matchContent',
    path: '/dashboard/contentList/matchContent/:contentId',
    Component: ContentListPage,
    key: 'matchContent',
  },
  {
    title: 'editUser',
    path: '/dashboard/userList/editUser/:userId',
    Component: EditUserDataPage,
    key: 'editUser',
  },
  {
    title: 'addStripeAccount',
    path: '/dashboard/addStripeAccount',
    Component: AddStripeUserPage,
    key: 'addStripeAccount',
  },
  {
    title: 'CreateRepoet',
    path: '/dashboard//reports',
    Component: RepoetPage,
    key: 'CreateRepoet',
  },
  {
    title: 'ChangeContent',
    path: '/dashboard//ChangeContent/',
    Component: ChangeContentPage,
    key: 'ChangeContent',
  },
  {
    title: 'editContent',
    path: '/dashboard/contentList/edit-content-data/:contentId',
    Component: EditContentPage,
    key: 'editContent',
  },
  {
    title: 'addOtherRevenue',
    path: '/dashboard/add-other-revenue',
    Component: AddOtherRevenuePage,
    key: 'addOtherRevenue',
  },
  {
    title: 'addnews',
    path: '/dashboard/addnews',
    Component: AddNewsPage,
  },
  {
    title: 'recentUpdates',
    path: '/dashboard/recentUpdates',
    Component: NewsListPage,
  },
  {
    title: 'editNews',
    path: '/dashboard/recentUpdates/editNews/:newsId',
    Component: EditNewsPage,
    key: 'editNews',
  },
  {
    title: 'newsManagement',
    path: '/dashboard/newsManagement',
    Component: NewsManagementPage,
  },
  {
    title: 'addnewcontent',
    path: '/dashboard/addnewcontent',
    Component: AddNewContentPage,
  },
  {
    title: 'getServices',
    path: '/dashboard/users-uploads',
    Component: ServicesList,
  },
  {
    title: 'yourVideos:videoId',
    path: '/dashboard/yourVideos/:videoId',
    Component: VideoHistoryPage,
  },
  {
    title: 'Change VAT Status',
    path: '/dashboard/changeVATStatus',
    Component: ChangeVATStatusPage,
  },
];
const agentRoutes = [
  {
    title: 'Statistics',
    path: '/dashboard/statistics',
    Component: StatisticsPage,
    key: 'Statistics',
  },
  {
    title: 'yourVideos',
    path: '/dashboard/yourVideos',
    Component: VideoListPage,
    key: 'yourVideos',
  },
  {
    title: 'yourVideos:videoId',
    path: '/dashboard/yourVideos/:videoId',
    Component: VideoHistoryPage,
  },
  {
    title: 'payout',
    path: '/dashboard/payout',
    Component: PayoutPage,
    key: 'payout',
  },
  {
    title: 'editProfile',
    path: '/dashboard/editProfile',
    Component: EditProfilePage,
    key: 'editProfile',
  },
  {
    title: 'changePassword',
    path: '/dashboard/changePassword',
    Component: ChangePasswordPage,
    key: 'changePassword',
  },
  {
    title: 'choosepaymentmethod',
    path: '/dashboard/choosepaymentmethod',
    Component: PaymentMethodPage,
    key: 'choosepaymentmethod',
  },
  {
    title: 'recentUpdates',
    path: '/dashboard/recentUpdates',
    Component: NewsListPage,
  },
  {
    title: 'addnewclient',
    path: '/dashboard/addnewclient',
    Component: AddNewComedianPage,
  },
  {
    title: 'clientslist',
    path: '/dashboard/clientslist',
    Component: ComedianListPage,
  },
  {
    title: 'linkcontent',
    path: '/dashboard/linkcontenttoclient',
    Component: LinkContentPage,
  },
  {
    title: 'uploadContent',
    path: '/dashboard/uploadContent',
    Component: UploadContentPage,
    key: 'uploadContent',
  },
  {
    title: 'contentVatChange',
    path: '/dashboard/contentVatChange',
    Component: UserVatTablePage,
    key: 'contentVatChange',
  },
];
const comedianRoutes = [
  {
    title: 'ComedianStatistics',
    path: '/dashboard/statistics',
    Component: ComedianStatisticsPage,
    key: 'ComedianStatistics',
  },
  {
    title: 'yourVideos',
    path: '/dashboard/yourVideos',
    Component: ComedianVideoListPage,
  },
  {
    title: 'yourVideos:videoId',
    path: '/dashboard/yourVideos/:videoId',
    Component: ComedianVideoHistoryPage,
    key: 'yourVideosvideoId',
  },
  {
    title: 'uploadContent',
    path: '/dashboard/uploadContent',
    Component: UploadContentPage,
    key: 'uploadContent',
  },
  {
    title: 'recentUpdates',
    path: '/dashboard/recentUpdates',
    Component: NewsListPage,
  },
  {
    title: 'editProfile',
    path: '/dashboard/editProfile',
    Component: EditProfilePage,
    key: 'editProfile',
  },
  {
    title: 'changePassword',
    path: '/dashboard/changePassword',
    Component: ChangePasswordPage,
    key: 'changePassword',
  },
];
const accountantRoutes = [
  {
    title: 'ComedianStatistics',
    path: '/dashboard/statistics',
    Component: AccountantStatisticsPage,
    key: 'AccountantStatistics',
  },
  {
    title: 'yourVideos',
    path: '/dashboard/videos',
    Component: AccountantVideoListPage,
  },
  {
    title: 'recentUpdates',
    path: '/dashboard/recentUpdates',
    Component: NewsListPage,
  },
  {
    title: 'editProfile',
    path: '/dashboard/editProfile',
    Component: EditProfilePage,
    key: 'editProfile',
  },
  {
    title: 'changePassword',
    path: '/dashboard/changePassword',
    Component: ChangePasswordPage,
    key: 'changePassword',
  },
  {
    title: 'accountantPayout',
    path: '/dashboard/Payout',
    Component: AccountantPayoutPage,
    key: 'accountantPayout',
  },

];
export {
  routes, adminRoutes, comedianRoutes, masterAdminRoutes, agentRoutes, accountantRoutes,
};

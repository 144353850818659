import { useEffect, useState } from 'react';

import { useAppDispatch } from 'hooks';
import { Table } from 'components/AntDesign';
import { ComponentLayout } from 'layouts';
import { adminPayoutColumns } from 'shared/objects/Users';
import { getAdminPayouts, getUserProfile } from 'store/admin/thunk';
import editedRows from 'utils/editedRows';

function AdminPayout() {
  const [rows, setRows] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [userName, setUserName] = useState('');
  const dispatch = useAppDispatch();
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await dispatch(getAdminPayouts()).unwrap();
      setRows(data.rows);

      const adminPayoutIds = data.rows.map((payout) => payout.userId);
      const userProfilesPromises = adminPayoutIds
        .map((userId) => dispatch(getUserProfile(userId)).unwrap());
      const userProfiles = await Promise.all(userProfilesPromises);
      const userNames = userProfiles.map((profile) => profile.data.name);

      setUserName(userNames.join(', '));
      const updatedRows = data.rows.map((row, index) => ({
        ...row,
        name: userNames[index],
      }));

      setRows(updatedRows);
    };

    fetchData();
  }, []);

  return (
    <ComponentLayout title="Payout" heading="Payout Requests">
      <Table
        columns={adminPayoutColumns}
        dataSource={editedRows(rows)}
        pagination={{ pageSize: 5 }}
        scroll={{ x: 500 }}
      />
    </ComponentLayout>
  );
}

export default AdminPayout;

import {
  Alert,
  Button, Modal, Space,
} from 'components/AntDesign';
import { useAppDispatch } from 'hooks';
import humanizeDuration from 'humanize-duration';
import PropsTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { getCurentWatched } from 'store/user/thunk';

function CurrentWatchedSeconds({ content }) {
  const dispatch = useAppDispatch();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [watchedSeconds, setWatchedSeconds] = useState(0);
  const [removed, setRemoved] = useState(false);
  const oldWatchedSeconds = content?.contentReports[0].watchedSeconds;
  const showModal = () => setIsModalVisible(true);
  const handleCancel = () => setIsModalVisible(false);
  useEffect(() => {
    if (isModalVisible) {
      if (content.id) {
        const fetchData = async () => {
          try {
            const { data } = await dispatch(getCurentWatched(content.id)).unwrap();
            setWatchedSeconds(data.total_watch_time);
          } catch (error) {
            setRemoved(true);
          }
        };
        fetchData();
      }
    }
  }, [isModalVisible]);
  let currentWatchedSeconds;
  if (oldWatchedSeconds === '0' || Number(oldWatchedSeconds) === 0) {
    currentWatchedSeconds = watchedSeconds;
  } else if (Number(oldWatchedSeconds) > Number(watchedSeconds) && Number(watchedSeconds) !== 0) {
    currentWatchedSeconds = Number(watchedSeconds) - Number(oldWatchedSeconds);
  } else {
    currentWatchedSeconds = Number(watchedSeconds);
  }
  return (
    <Space size="middle">
      <Button type="primary" className="form-button" onClick={showModal}>
        UP TO DATE STATS
      </Button>
      <Modal
        title="Up to date stats"
        visible={isModalVisible}
        onOk={handleCancel}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Close
          </Button>,
        ]}
      >
        {removed === false ? (
          <>
            <Alert
              style={{ marginTop: '5px', marginBottom: '10px' }}
              message="Your total watched time for current quarter is:"
              description={humanizeDuration(currentWatchedSeconds * 1000, { round: true })}
              type="info"
              showIcon
            />
            {watchedSeconds !== '0' && Number(watchedSeconds) !== 0 && (
            <Alert
              style={{ marginTop: '5px', marginBottom: '10px' }}
              message="Your total watched time for current quarter in minutes is:"
              description={(currentWatchedSeconds / 60).toFixed(2)}
              type="info"
              showIcon
            />
            )}
          </>
        ) : (
          <Alert
            style={{ marginTop: '5px', marginBottom: '10px' }}
            message="The content is not on the platform anymore"
            type="info"
            showIcon
          />
        )}

      </Modal>
    </Space>
  );
}

export default CurrentWatchedSeconds;

CurrentWatchedSeconds.propTypes = {
  content: PropsTypes.shape({
    id: PropsTypes.number.isRequired,
    contentReports: PropsTypes.arrayOf(PropsTypes.shape({
      id: PropsTypes.number.isRequired,
      watchedSeconds: PropsTypes.string.isRequired,
    })).isRequired,
  }).isRequired,

};

const itemsSuperAdmin = [
  {
    title: 'Home',
    icon: 'fa fa-dashboard',
    children: [
      {
        title: 'Statistics',
        path: '/dashboard/statistics',
        key: 'statistics',
      },
      {
        title: 'Content List',
        path: '/dashboard/contentlist',
        key: 'contentlist',
      },
      {
        title: 'Content Update',
        path: '/dashboard/ChangeContent',
        key: 'ChangeContent',
      },
      {
        title: 'Add Deal Revenue',
        path: '/dashboard/add-other-revenue',
        key: 'AddOtherRevenue',
      },
      {
        title: 'Add New Content',
        path: '/dashboard/addNewContent',
        key: 'addNewContent',
      },
    ],
  },
  {
    title: 'Updates',
    icon: 'fa  fa-newspaper-o',
    children: [
      {
        title: 'Add News',
        path: '/dashboard/addnews',
        key: 'addnews',
      },
      {
        title: 'Recent Updates',
        path: '/dashboard/recentupdates',
        key: 'recentupdates',
      },
      {
        title: 'Manage News',
        path: '/dashboard/newsManagement',
        key: 'newsManagement',
      },
    ],

  }, {
    title: 'User Uploaded Content',
    icon: 'fa  fa-upload',
    path: '/dashboard/users-uploads',
  },
  {
    title: 'Finance',
    icon: 'fa fa-money',
    children: [
      {
        title: 'Payout',
        path: '/dashboard/adminPayout',
        key: 'payout',
      },
      {
        title: 'Reports',
        path: '/dashboard/reports',
        key: 'reports',
      },
      {
        title: 'Change VAT Status',
        path: '/dashboard/changeVATStatus',
        key: 'changeVATStatus',
      },
      {
        title: 'Add Stripe Account',
        path: '/dashboard/addStripeAccount',
        key: 'addStripeAccount',
      },
    ],
  },
  {
    title: 'User Management',
    icon: 'fa fa-users',
    children: [
      {
        title: 'User List',
        path: '/dashboard/userlist',
        key: 'userlist',
      },

      {
        title: 'List of New Users',
        path: '/dashboard/newusers',
        key: 'newusers',
      },
      {
        title: 'List of Rejected Users',
        path: '/dashboard/rejectedusers',
        key: 'rejectedusers',
      },
      {
        title: 'List of Banned Users',
        path: '/dashboard/bannedusers',
        key: 'bannedUsers',
      },
      {
        title: 'Add New User',
        path: '/dashboard/addNewUser',
        key: 'addNewUser',
      },
    ],
  },
  {
    title: 'Account Settings',
    icon: 'fa fa-cog',

    children: [
      {
        title: 'Edit Profile',
        path: '/dashboard/editprofile',
        key: 'profile',
      },
      {
        title: 'Change Password',
        path: '/dashboard/changepassword',
        key: 'changepassword',
      },

    ],
    path: '/dashboard/AccountSettings',
  },
  {
    title: 'Dashboard Settings',
    icon: 'fa fa-cog',
    path: '/dashboard/Settings',
  },
  {
    title: 'Logout',
    icon: 'fa fa-sign-out',
    path: '/logout',
  },

];

const itemsAdmin = [
  {
    title: 'Home',
    icon: 'fa fa-dashboard',
    children: [
      {
        title: 'Statistics',
        path: '/dashboard/statistics',
        key: 'statistics',
      },
      {
        title: 'Content List',
        path: '/dashboard/contentlist',
        key: 'contentlist',
      },
      {
        title: 'Content Update',
        path: '/dashboard/ChangeContent',
        key: 'ChangeContent',
      },
      {
        title: 'Add Deal Revenue',
        path: '/dashboard/add-other-revenue',
        key: 'AddOtherRevenue',
      },
      {
        title: 'Add New Content',
        path: '/dashboard/addNewContent',
        key: 'addNewContent',
      },
    ],
  },
  {
    title: 'Updates',
    icon: 'fa  fa-newspaper-o',
    children: [
      {
        title: 'Add News',
        path: '/dashboard/addnews',
        key: 'addnews',
      },
      {
        title: 'Recent Updates',
        path: '/dashboard/recentupdates',
        key: 'recentupdates',
      },
      {
        title: 'Manage News',
        path: '/dashboard/newsManagement',
        key: 'newsManagement',
      },
    ],

  },
  {
    title: 'Finance',
    icon: 'fa fa-money',
    children: [
      {
        title: 'Payout',
        path: '/dashboard/adminPayout',
        key: 'revenue',
      },
      {
        title: 'Reports',
        path: '/dashboard/reports',
        key: 'reports',
      },
      {
        title: 'Change VAT Status',
        path: '/dashboard/changeVATStatus',
        key: 'changeVATStatus',
      },
      {
        title: 'Add Stripe Account',
        path: '/dashboard/addStripeAccount',
        key: 'addStripeAccount',
      },
    ],
  },
  {
    title: 'User Management',
    icon: 'fa fa-users',
    children: [
      {
        title: 'User List',
        path: '/dashboard/userlist',
        key: 'userlist',
      },

      {
        title: 'List of New Users',
        path: '/dashboard/newusers',
        key: 'newusers',
      },
      {
        title: 'List of Rejected Users',
        path: '/dashboard/rejectedusers',
        key: 'rejectedusers',
      },
      {
        title: 'List of Banned Users',
        path: '/dashboard/bannedusers',
        key: 'bannedUsers',
      },
    ],
  },
  {
    title: 'Account Settings',
    icon: 'fa fa-cog',

    children: [
      {
        title: 'Edit Profile',
        path: '/dashboard/editprofile',
        key: 'profile',
      },
      {
        title: 'Change Password',
        path: '/dashboard/changepassword',
        key: 'changepassword',
      },

    ],
    path: '/dashboard/AccountSettings',
  },
  {
    title: 'Logout',
    icon: 'fa fa-sign-out',
    path: '/logout',
  },
];
const itemsAGENT = [
  {
    title: 'Home',
    icon: 'fa fa-dashboard',
    children: [
      {
        title: 'Your Statistics ',
        path: '/dashboard/statistics',
        key: 'yourstatistics',
      },
      {
        title: 'Video List ',
        path: '/dashboard/yourvideos',
        key: 'yourvideos',
      },
    ],
  },
  {
    title: 'Updates',
    icon: 'fa  fa-newspaper-o',
    children: [
      {
        title: 'Recent Updates',
        path: '/dashboard/recentupdates',
        key: 'recentupdates',
      },
    ],

  },
  {
    title: 'Finance',
    icon: 'fa fa-money',
    children: [
      {
        title: 'Choose Payment Method',
        path: '/dashboard/choosepaymentmethod',
        key: 'choosepaymentmethod',
      },
      {
        title: 'Change VAT Status',
        path: '/dashboard/contentVatChange',
        key: 'contentVatChange',
      },
      {
        title: 'Payout',
        path: '/dashboard/payout',
        key: 'revenue',
      },
    ],
  },
  {

    title: 'Manage Clients',
    icon: 'fa fa-users',
    children: [
      {
        title: 'Add New Client',
        path: '/dashboard/addnewclient',
        key: 'addnewclient',
      },
      {
        title: 'Clients List',
        path: '/dashboard/clientslist',
        key: 'clientslist',
      },
      {
        title: 'Link Content to Client',
        path: '/dashboard/linkcontenttoclient',
        key: 'linkcontenttoclient',
      },
    ],
  },
  {
    title: 'Upload Your Content',
    icon: 'fa  fa-upload',
    path: '/dashboard/uploadContent',
  },
  {
    title: 'Account Settings',
    icon: 'fa fa-cog',

    children: [
      {
        title: 'Edit Profile',
        path: '/dashboard/editprofile',
        key: 'profile',
      },
      {
        title: 'Change Password',
        path: '/dashboard/changepassword',
        key: 'changepassword',
      },

    ],
    path: '/dashboard/AccountSettings',
  },
  {
    title: 'Logout',
    icon: 'fa fa-sign-out',
    path: '/logout',
  },

];

const itemsCOMEDIAN = [
  {
    title: 'Home',
    icon: 'fa fa-dashboard',
    children: [
      {
        title: 'Statistics ',
        path: '/dashboard/statistics',
        key: 'statistics',
      },
      {
        title: 'Video List ',
        path: '/dashboard/yourvideos',
        key: 'yourvideos',
      },
    ],
  },
  {
    title: 'Updates',
    icon: 'fa  fa-newspaper-o',
    children: [
      {
        title: 'Recent Updates',
        path: '/dashboard/recentupdates',
        key: 'recentupdates',
      },
    ],

  },
  {
    title: 'Upload Your Content',
    icon: 'fa  fa-upload',
    path: '/dashboard/uploadContent',
  },
  {
    title: 'Account Settings',
    icon: 'fa fa-cog',

    children: [
      {
        title: 'Edit Profile',
        path: '/dashboard/editprofile',
        key: 'profile',
      },
      {
        title: 'Change Password',
        path: '/dashboard/changepassword',
        key: 'changepassword',
      },

    ],
    path: '/dashboard/AccountSettings',
  },
  {
    title: 'Logout',
    icon: 'fa fa-sign-out',
    path: '/logout',
  },
];
const itemsAccountant = [
  {
    title: 'Home',
    icon: 'fa fa-dashboard',
    children: [
      {
        title: 'Statistics ',
        path: '/dashboard/statistics',
        key: 'statistics',
      },
      {
        title: 'Video List ',
        path: '/dashboard/videos',
        key: 'yourvideos',
      },
    ],
  },
  {
    title: 'Finance',
    icon: 'fa fa-money',
    children: [
      {
        title: 'Payout',
        path: '/dashboard/payout',
        key: 'revenue',
      },
    ],
  },
  {
    title: 'Updates',
    icon: 'fa  fa-newspaper-o',
    children: [
      {
        title: 'Recent Updates',
        path: '/dashboard/recentupdates',
        key: 'recentupdates',
      },
    ],

  },
  {
    title: 'Account Settings',
    icon: 'fa fa-cog',

    children: [
      {
        title: 'Edit Profile',
        path: '/dashboard/editprofile',
        key: 'profile',
      },
      {
        title: 'Change Password',
        path: '/dashboard/changepassword',
        key: 'changepassword',
      },

    ],
    path: '/dashboard/AccountSettings',
  },
  {
    title: 'Logout',
    icon: 'fa fa-sign-out',
    path: '/logout',
  },
];
export {
  itemsSuperAdmin, itemsAdmin, itemsAGENT, itemsCOMEDIAN, itemsAccountant,
};

import { EditProfile } from 'components/Dashboard';

function EditProfilePage() {
  return (
    <div>
      <EditProfile />
    </div>
  );
}

export default EditProfilePage;

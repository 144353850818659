/* eslint-disable no-unused-vars */
import {
  Form, Button, AutoComplete, Select,
} from 'components/AntDesign';
import { useAppDispatch, useAppSelector } from 'hooks';

import { useState, useEffect } from 'react';
import { addComedianContent } from 'store/content/thunk';
import { getComedianList, getUserContent } from 'store/user/thunk';
import { validationMessages } from 'utils';

function LinkComedianContent() {
  const [contents, setContents] = useState([]);
  const [users, setUsers] = useState([]);
  const [contentIds, setContentIds] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [value, setValue] = useState('');
  const { user } = useAppSelector((state) => state.checkAuth);

  const dispatch = useAppDispatch();
  const { Option } = Select;

  useEffect(() => {
    const fetchData = async () => {
      const payload = await dispatch(getComedianList()).unwrap();
      setUsers(payload.comedians);
    };
    fetchData();
  }, []);
  useEffect(() => {
    if (user.id) {
      const fetchData = async () => {
        const response = await dispatch(
          getUserContent({ page: 1, limit: 100 }),
        ).unwrap();
        setContents(response.data?.rows);
      };
      fetchData();
    }
  }, []);
  const onFinish = async () => {
    const userId = users.find((data) => data.email === selectedUser).id;
    const payload = {
      userId,
      contentIds,
    };
    dispatch(addComedianContent(payload));
  };

  const contentsOptions = Object.values(contents).map((option) => ({
    value: option.title,
    id: option.id,
  }));
  const usersOptions = Object.values(users).map((option) => ({
    value: option.email,
    id: option.id,
  }));
  const onChangeUser = (id) => {
    setSelectedUser(id);
  };
  const onChangeContent = (text) => {
    setContentIds(text);
  };

  const filterOption = {
    filterOption: (inputValue, option) => option?.value?.toUpperCase()
      .indexOf(inputValue.toUpperCase()) !== -1,
  };

  return (
    <>
      <div className="heading-nav">
        <div className="heading-icon" />
        <div className="title-heading">Link Comedian Content</div>
      </div>

      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        autoComplete="off"
        className="my-form"
        layout="vertical"
        requiredMark={false}
      >
        <Form.Item label="The Content Name" name="id">

          <Select size="middle" placeholder="Select a content" mode="multiple" onChange={onChangeContent}>
            {Object.values(contents).map((option) => (
              <Option key={option.id} value={option.id}>
                {option.title}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Enter the user email"
          name="userId"
          rules={validationMessages.userId}
        >
          <AutoComplete
            size="middle"
            options={usersOptions}
            value={selectedUser}
            onChange={onChangeUser}
            placeholder="Search new owner email"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="form-button"
          >
            Update Content
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default LinkComedianContent;

import { useAppDispatch, useAppSelector } from 'hooks';
import {
  Input, Button, Form, Select,
} from 'components/AntDesign';
import { ComponentLayout } from 'layouts';
import { validationMessages } from 'utils';
import { uploadContentByComedian } from 'store/user/thunk';
import './style.css';

export default function UploadContent() {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { Option } = Select;
  const { user } = useAppSelector((state) => state.checkAuth);

  const onFinish = async (values) => {
    const { id } = user;
    const userId = id;
    const data = {
      type: values.type,
      text: values.text,
      link: values.link,
      userId,
    };
    await dispatch(uploadContentByComedian(data)).unwrap();
    form.resetFields();
  };
  const types = [
    { value: 'Special' },
    { value: 'Short Form clip' },
    { value: 'Social assets' },
    { value: 'Art or imagery' },
    { value: 'Other' },
  ];

  return (
    <ComponentLayout heading="Share your content with us">
      <Form
        form={form}
        name="basic"
        requiredMark={false}
        onFinish={onFinish}
        autoComplete="off"
      >
        <div className="add-new-user-form-form-f">
          <Form.Item
            name="type"
            rules={validationMessages.required}
          >
            <Select size="middle" placeholder="Select your content type">
              {Object.values(types).map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.value}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="text"
            rules={validationMessages.required}
          >
            <Input.TextArea placeholder="Describe your content" />
          </Form.Item>
          <Form.Item
            name="link"
            rules={validationMessages.required}
          >
            <Input placeholder="Content Link" />
          </Form.Item>
          <Form.Item noStyle>
            <Button className="form-button" type="primary" htmlType="submit">
              Send content to us
            </Button>
          </Form.Item>
        </div>
      </Form>
    </ComponentLayout>
  );
}

import humanizeDuration from 'humanize-duration';
import PropsTypes from 'prop-types';

import { ComponentLayout } from 'layouts';
import {
  comedianListColumns, comedianstatisticsColumns, statisticsColumns, videoListColumns,
  comedioanVideoListColumns,
  accountantVideoListColumns,
} from 'shared/objects/Users';
import { Alert, Button, Table } from 'components/AntDesign';
import { useNavigate } from 'react-router-dom';
import { USER_CONTENT_ENDPOINT } from 'shared/constants/endpoints';
import OtherRevuneModal from './OtherRevuneModal';
import CurrentWatchedSeconds from './CurrentWatchedSeconds';
import CurrentWatchedSecondsComedian from './CurrentWatchedSecondsComedian';

export default function DetailedView({ rows, listToSee }) {
  const navigate = useNavigate();
  const editColumns = [
    ...videoListColumns,
    {
      title: 'Content History',
      key: 'action',
      render: (_text, record) => (
        <div className="table-cont">
          <Button
            onClick={() => navigate(USER_CONTENT_ENDPOINT(record.key))}
            type="primary"
            className="table-btn"
          >
            View
          </Button>

        </div>
      ),
    },
  ];

  return (
    <div>
      {listToSee === 'videoList' ? (
        <Table
          columns={editColumns}
          dataSource={rows.map((row) => ({
            title: row.title,
            launchDate: row.launchDate.slice(0, 10),
            runtime: humanizeDuration(row.runtime * 1000, {
              units: ['h', 'm', 's'],
            }),
            owedAccRevenue: Number(row.owedAccRevenue).toFixed(2),
            key: row.id,
          }))
            .sort((a, b) => new Date(a.launchDate) - new Date(b.launchDate))}
          scroll={{ x: 500 }}
          pagination={false}
        />
      )
        : listToSee === 'comedianVideoList' ? (
          <Table
            columns={comedioanVideoListColumns}
            dataSource={rows.map((row) => ({
              title: row.title,
              runtime: humanizeDuration(row.runtime * 1000, {
                units: ['h', 'm', 's'],
              }),
              launchDate: row.launchDate.slice(0, 10),
              watchedSeconds: row.contentReports[0].watchedSeconds,
              key: row.id,
            }))
              .sort((a, b) => new Date(a.launchDate) - new Date(b.launchDate))}
            pagination={{ pageSize: 5 }}
            scroll={{ x: 500 }}
          />

        ) : listToSee === 'accountantVideoList' ? (
          <Table
            columns={accountantVideoListColumns}
            dataSource={rows.map((row) => ({
              title: row.title,
              owedAccRevenue: Number(row.owedAccRevenue).toFixed(2),
              owedRevenue: Number(row?.contentReports[0].owedRevenue).toFixed(2),
              key: row.id,
            }))
              .sort((a, b) => new Date(a.launchDate) - new Date(b.launchDate))}
            pagination={{ pageSize: 5 }}
            scroll={{ x: 500 }}
          />

        ) : listToSee === 'comedianList' ? (
          <Table
            columns={comedianListColumns}
            dataSource={rows.map((row) => ({
              name: row.name,
              email: row.email,
              key: row.id,
              totalRevenue: Number(row.totalRevenue).toFixed(3),
              paidRevenue: Number(row.paidRevenue).toFixed(3),
            }))}
            pagination={{ pageSize: 5 }}
            scroll={{ x: 500 }}
          />

        ) : listToSee === 'comedianstatistics' ? (
          <>
            <Table
              columns={comedianstatisticsColumns}
              dataSource={rows.map((row) => ({
                title: row.title,
                watchedSeconds: humanizeDuration(
                  row.contentReports[0].watchedSeconds * 1000,
                  { units: ['h', 'm', 's'] },
                ),
                currentWatchedSeconds: <CurrentWatchedSecondsComedian content={row} />,
                notes: row.notes || '-',
                key: row.title,
                launchDate: new Date(row.launchDate), // Convert launchDate to a Date object
              }))
                .sort((a, b) => new Date(a.launchDate) - new Date(b.launchDate))}
              pagination={{ pageSize: 5 }}
              scroll={{ x: 500 }}
            />
            <Alert message="If you can't find the content you are looking for, please check the Home - Video List." type="info" showIcon />
          </>
        ) : (
          <ComponentLayout heading="Detailed View For Previous Quarter only">
            <Table
              columns={statisticsColumns}
              dataSource={rows.map((row) => ({
                title: row.title,
                watchedSeconds: humanizeDuration(
                  row.contentReports[0].watchedSeconds * 1000,
                  { units: ['h', 'm', 's'] },
                ),
                owedRevenue: `£${row.contentReports[0].owedRevenue.slice(
                  0,
                  5,
                )} `,
                details: (
                  <OtherRevuneModal
                    otherRevenue={row?.contentReports[0]?.otherRevenue}
                    contentReports={row?.contentReports}
                    content={row}
                  />
                ),
                currentWatchedSeconds: <CurrentWatchedSeconds content={row} />,
                notes: row.notes || '-',
                key: row.title,
                launchDate: new Date(row.launchDate), // Convert launchDate to a Date object
              }))
                .sort((a, b) => new Date(a.launchDate) - new Date(b.launchDate))}
              pagination={{ pageSize: 5 }}
              scroll={{ x: 500 }}
            />
            <Alert message="If you can't find the content you are looking for, please check the Home - Video List." type="info" showIcon />
          </ComponentLayout>
        )}
    </div>
  );
}

DetailedView.propTypes = {
  rows: PropsTypes.arrayOf(PropsTypes.object).isRequired,
  listToSee: PropsTypes.string,
};

DetailedView.defaultProps = {
  listToSee: '',
};

import React, { useState, useEffect } from 'react';
import { useAppDispatch } from 'hooks';
import {
  Form, Button, AutoComplete, Select, Text,
} from 'components/AntDesign';
import { getMatchedContent, getVatStatus, changeVatStatus } from 'store/admin/thunk';
import { Loader } from 'shared/components';

function ChangeVATStatusPage() {
  const [value, setValue] = useState('');
  const [contents, setContents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [vatStatus, setVatStatus] = useState(null);
  const dispatch = useAppDispatch();

  const contentsOptions = contents.map((content) => ({
    value: content.title,
    id: content.id,
  }));

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await dispatch(getMatchedContent()).unwrap();
      setContents(data);
    };
    fetchData();
  }, [dispatch]);

  const fetchVatStatus = async (contentId) => {
    try {
      const { data } = await dispatch(getVatStatus(contentId)).unwrap();
      setVatStatus(data.vat);
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const contentId = contents.find((content) => content.title === value).id;
      await dispatch(changeVatStatus({ id: contentId, vat: values.vat })).unwrap();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onChange = (text) => {
    setValue(text);
    const selectedContent = contents.find((content) => content.title === text);
    if (selectedContent) {
      fetchVatStatus(selectedContent.id);
    }
  };

  const filterOption = (inputValue, option) => option.value
    .toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;

  return (
    <Form
      name="change-vat-status"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      autoComplete="off"
      className="my-form"
      layout="vertical"
      requiredMark={false}
    >
      <Form.Item label="Content Name" name="contentName">
        <AutoComplete
          size="large"
          value={value}
          options={contentsOptions}
          onChange={onChange}
          placeholder="Search content name"
          filterOption={filterOption}
        />
      </Form.Item>
      <Text className="vat-status">
        Current VAT Status:
        {' '}
        {vatStatus === null ? 'Non selected' : vatStatus ? 'Yes' : 'No'}
      </Text>
      <Form.Item
        name="vat"
        rules={[{ required: true, message: 'Please select the VAT status' }]}
      >
        <Select
          placeholder="Select VAT status"
          value={vatStatus}
          onChange={(val) => setVatStatus(val)}
        >
          <Select.Option value>Yes</Select.Option>
          <Select.Option value={false}>No</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" size="large" className="form-button" disabled={loading}>
          {loading ? <Loader /> : 'Update VAT Status'}
        </Button>
      </Form.Item>
    </Form>

  );
}

export default ChangeVATStatusPage;

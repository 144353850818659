import { ComponentLayout } from 'layouts';
import { Tabs } from 'components/AntDesign';
import { ChangeContentOwner, UpdateContent } from 'components';

function ChangeContent() {
  const items = [
    {
      label: 'Change the content Owner',
      key: '1',
      children: <ChangeContentOwner />,
    },
    {
      label: 'Update the content',
      key: '2',
      children: <UpdateContent />,
    },
  ];
  return (
    <ComponentLayout title="Edit Content">
      <Tabs
        defaultActiveKey="1"
        type="card"
        size="large"
        items={items}
      />
    </ComponentLayout>
  );
}

export default ChangeContent;

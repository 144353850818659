import { EditNews } from 'components';
import React from 'react';

function EditNewsPage() {
  return (
    <div><EditNews /></div>
  );
}

export default EditNewsPage;

/* eslint-disable max-len */
import {
  Alert,
  Button, Modal, Space, Table,
} from 'components/AntDesign';

import PropsTypes from 'prop-types';
import { useState } from 'react';

function VideoRevModal({ otherRevenue, content }) {
  const {
    recoveredCosts, filmingCosts, advance, feePaid,
  } = content;
  const launchDate = new Date(content?.launchDate);
  const currentDate = new Date();
  const targetDate = new Date(launchDate);
  targetDate.setFullYear(targetDate.getFullYear() + 2);
  const moneytoRecover = Number(filmingCosts) + Number(advance) + Number(feePaid);

  const isTwoYearsAfter = currentDate >= targetDate;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => setIsModalVisible(true);
  let totalRevenue;
  let newArr = [];
  const subRevenue = [];
  if (otherRevenue.length > 0) {
    const totalOtherRevenue = otherRevenue.reduce((total, revenue) => total
  + Number(revenue.revenue), 0);
    if (Number(content.owedRevenue) === totalOtherRevenue) {
      totalRevenue = (totalOtherRevenue);
      newArr = otherRevenue.concat({ service: 'Subscription Revenue Share', revenue: 0.00 });
    } else if (Number(content.owedRevenue) > totalOtherRevenue) {
      totalRevenue = (Number(content.owedRevenue));
      newArr = otherRevenue.concat({ service: 'Subscription Revenue Share', revenue: (Number(content.owedRevenue) - totalOtherRevenue).toFixed(2) });
    } else {
      totalRevenue = (Number(content.owedRevenue) + totalOtherRevenue);
      newArr = otherRevenue.concat({ service: 'Subscription Revenue Share', revenue: Number(content.owedRevenue).toFixed(2) });
    }
  } else {
    totalRevenue = (content[0].owedRevenue);
    newArr = subRevenue.concat({ service: 'Subscription Revenue Share', revenue: Number(content.owedRevenue).toFixed(2) });
  }
  const handleCancel = () => setIsModalVisible(false);
  return (
    <Space size="middle">
      <Button type="primary" className="form-button" onClick={showModal}>
        Get Details
      </Button>
      <Modal
        title="Deals & Sub Revenue"
        visible={isModalVisible}
        onOk={handleCancel}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Close
          </Button>,
        ]}
      >

        <Table dataSource={newArr} pagination={false}>
          <Table.Column title="Service" dataIndex="service" key={(record, index) => index} />
          <Table.Column title="Revenue" dataIndex="revenue" key={(record, index) => index} />
        </Table>

        <Alert
          style={{ marginTop: '5px', marginBottom: '10px' }}
          message={`Your total revenue is £${Number(totalRevenue).toFixed(2)}`}
          type="success"
        />
        <div>
          {
            isTwoYearsAfter === false && Number(moneytoRecover) > 0 ? (
              <Alert
                style={{ marginTop: '5px', marginBottom: '10px' }}
                description={(
                  <>
                    The subscription revenue listed above contributes to any filming costs/advance recoupment so may not show in the
                    `total revenue` figure if the show has not recouped.
                    <br />
                    <br />
                    Platform Launch Date:
                    {' '}
                    {launchDate.toLocaleDateString('en-GB')}
                    <br />
                    Total to Recoup:
                    {Number(moneytoRecover).toFixed(2)}
                    <br />
                    Recouped to Date:
                    {Number(recoveredCosts).toFixed(2)}
                    <br />
                    Remaining to Recoup:
                    {(Number(moneytoRecover) - Number(recoveredCosts)).toFixed(2)}
                    <br />
                    {' '}
                    <br />

                    *NB the remaining amount to recoup will be written off after two years on the platform.

                  </>
                  )}
                type="info"
                showIcon
              />
            ) : null
          }
        </div>
      </Modal>
    </Space>
  );
}

export default VideoRevModal;

VideoRevModal.propTypes = {
  otherRevenue: PropsTypes.arrayOf(PropsTypes.shape({
    service: PropsTypes.string.isRequired,
    revenue: PropsTypes.number.isRequired,
  })).isRequired,
  content: PropsTypes.shape({
    id: PropsTypes.number.isRequired,
    owedAccRevenue: PropsTypes.string.isRequired,
    recoveredCosts: PropsTypes.string.isRequired,
    launchDate: PropsTypes.string.isRequired,
    filmingCosts: PropsTypes.string.isRequired,
    advance: PropsTypes.string.isRequired,
    feePaid: PropsTypes.string.isRequired,
    owedRevenue: PropsTypes.string.isRequired,
  }).isRequired,

};

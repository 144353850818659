import { UserVatTable } from 'components';
import { ComponentLayout } from 'layouts';

function LinkContentPage() {
  return (
    <ComponentLayout>
      <UserVatTable />
    </ComponentLayout>
  );
}

export default LinkContentPage;

import {
  Alert,
  Button, Modal, Space,
} from 'components/AntDesign';
import { useAppDispatch } from 'hooks';
import humanizeDuration from 'humanize-duration';
import PropsTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { getCurentWatchedComedian } from 'store/user/thunk';

function CurrentWatchedSecondsComedian({ content }) {
  const dispatch = useAppDispatch();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [watchedSeconds, setWatchedSeconds] = useState(0);
  const [removed, setRemoved] = useState(false);
  const oldWatchedSeconds = content?.contentReports[0].watchedSeconds;
  const showModal = () => setIsModalVisible(true);
  const handleCancel = () => setIsModalVisible(false);
  useEffect(() => {
    if (isModalVisible) {
      if (content.id) {
        const fetchData = async () => {
          try {
            const { data } = await dispatch(getCurentWatchedComedian(content.id)).unwrap();
            setWatchedSeconds(data.total_watch_time);
          } catch (error) {
            setRemoved(true);
          }
        };
        fetchData();
      }
    }
  }, [isModalVisible]);
  let currentWatchedSeconds;
  if (oldWatchedSeconds === '0') {
    currentWatchedSeconds = watchedSeconds;
  } else {
    currentWatchedSeconds = watchedSeconds - oldWatchedSeconds;
  }
  return (
    <Space size="middle">
      <Button type="primary" className="form-button" onClick={showModal}>
        UP TO DATE STATS
      </Button>
      <Modal
        title="Up to date stats"
        visible={isModalVisible}
        onOk={handleCancel}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Close
          </Button>,
        ]}
      >
        {removed === false ? (
          <Alert
            style={{ marginTop: '5px', marginBottom: '10px' }}
            message="Your total watched time for current quarter is:"
            description={humanizeDuration(currentWatchedSeconds * 1000, { round: true })}
            type="info"
            showIcon
          />
        ) : (
          <Alert
            style={{ marginTop: '5px', marginBottom: '10px' }}
            message="The content is not on the platform anymore"
            type="info"
            showIcon
          />
        )}

      </Modal>
    </Space>
  );
}

export default CurrentWatchedSecondsComedian;

CurrentWatchedSecondsComedian.propTypes = {
  content: PropsTypes.shape({
    id: PropsTypes.number.isRequired,
    contentReports: PropsTypes.arrayOf(PropsTypes.shape({
      id: PropsTypes.number.isRequired,
      watchedSeconds: PropsTypes.string.isRequired,
    })).isRequired,
  }).isRequired,

};

import { PaymentSystem } from 'components';
import { ComponentLayout } from 'layouts';

function PaymentMethodPage() {
  return (
    <ComponentLayout title="Payment Method" heading="Your Payment Method">
      <PaymentSystem />
    </ComponentLayout>
  );
}
export default PaymentMethodPage;

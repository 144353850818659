import React, { useState, useEffect } from 'react';
import { ComponentLayout } from 'layouts';
import './style.css';
import { getNews } from 'store/user/thunk';
import { useAppDispatch } from 'hooks';
import logo from 'assets/images/default-poster.png';
import { Image } from 'shared/components';
import ReactReadMoreReadLess from 'react-read-more-read-less';
import { Button } from 'antd';

function NewsList() {
  const [rows, setRows] = useState([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const { payload } = await dispatch(getNews());
      setRows(payload);
    };
    fetchData();
  }, [dispatch]);

  return (
    <ComponentLayout title="Updates Section" heading="Read Latest Updates">

      {rows.sort((a, b) => b.id - a.id).map((post) => (
        <div key={post.id} className="posts-container">
          <div className="news-single">
            <div className="news">
              <div className="news-img">
                <Image src={post.image} alt={post.title} className="news-image" />
              </div>
              <div className="news-content">
                <div className="news-details">
                  <div className="news-author-img">
                    <Image src={logo} alt="User" className="user-avatar" />
                  </div>
                  <div className="news-author-details">
                    <h4 className="news-author-name">{post.userName}</h4>
                  </div>
                </div>
                <h2 className="news-title">{post.title}</h2>
                <p className="news-desc">
                  <ReactReadMoreReadLess
                    charLimit={500}
                    readMoreText="Read more"
                    readLessText="Read less"
                    readMoreStyle={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}
                    readLessStyle={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}
                  >
                    {post.newsContent}
                  </ReactReadMoreReadLess>
                </p>
                {
                  post.link && (
                    <Button
                      className="form-button news-button"
                      type="primary"
                      onClick={() => window.open(post.link, '_blank')}
                    >
                      Watch Video
                    </Button>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      ))}

    </ComponentLayout>
  );
}

export default NewsList;

import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'components/AntDesign'; // Assuming you have a modal component

function PDFModal({ visible, onClose, invoice }) {
  return (
    <Modal
      title="Invoice"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={800}
    >
      <iframe
        title="PDF Viewer"
        src={`data:application/pdf;base64,${invoice}`}
        width="100%"
        height="500px"
      />
    </Modal>
  );
}

PDFModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  invoice: PropTypes.string.isRequired,
};

export default PDFModal;

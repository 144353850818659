/* eslint-disable react/jsx-props-no-spreading */

import { useEffect, useState } from 'react';
import { useAppDispatch } from 'hooks';
import { getMatchedContent } from 'store/admin/thunk';
import { Loader } from 'shared/components';
import VideoModal from '../VideoModal';
import VideoCardEdit from '../VideoCardEdit';

function UpdateContent() {
  const [contents, setContents] = useState([]);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await dispatch(getMatchedContent()).unwrap();
      setContents(data);
    };
    fetchData();
  }, []);

  return (
    <>
      <VideoModal />
      {
    contents?.length ? (
      <div className="contents-list-bottom">
        <div className="contents-list">
          {
            contents
              ? contents.map(({ id, title, permalink }) => (
                <VideoCardEdit
                  key={id}
                  id={id}
                  title={title}
                  permalink={permalink}
                />
              ))
              : <Loader />
          }
        </div>

      </div>
    ) : (
      <div>
        There are no contents to update
      </div>
    )
  }
    </>
  );
}

export default UpdateContent;

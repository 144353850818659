import { UploadContent } from 'components';
import React from 'react';

function UploadContentPage() {
  return (
    <UploadContent />
  );
}

export default UploadContentPage;

import { useAppDispatch, useAppSelector } from 'hooks';
import {
  Input, Button, Form,
} from 'components/AntDesign';
import { ROLES } from 'shared/constants';
import { ComponentLayout } from 'layouts';
import { addNews } from 'store/admin/thunk';
import { validationMessages } from 'utils';
import { useState } from 'react';
import ImageUploader from './uploadImage';

export default function AddNews() {
  const [form] = Form.useForm();
  const [image, setImage] = useState(null);
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.checkAuth);

  const onFinish = async (values) => {
    const { id } = user;
    const userId = id;
    dispatch(addNews({
      ...values, userId, image,
    }));
  };
  return (
    <ComponentLayout title="Add News" heading="Add New Update">
      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
        initialValues={{ roleId: ROLES.AGENT }}
      >
        <div className="add-new-user-form-form-f">
          <Form.Item
            name="title"
            rules={[
              {
                required: true,
                message: validationMessages.required,
              },
            ]}
          >
            <Input placeholder="Title" />
          </Form.Item>
          <Form.Item
            name="newsContent"
            rules={[
              {
                required: true,
                message: validationMessages.required,
              },
            ]}
          >
            <Input.TextArea placeholder="News Content" />
          </Form.Item>

          <Form.Item
            name="link"
          >
            <Input placeholder="Link" />
          </Form.Item>

          <Form.Item
            name="image"
            rules={[
              {
                required: true,
                message: validationMessages.required,
              },
            ]}
          >
            <div className="ImageUploader">
              <ImageUploader submitImageToForm={(url) => setImage(url)} />
            </div>
          </Form.Item>
          <Form.Item noStyle>
            <Button className="form-button" type="primary" htmlType="submit">
              Publish
            </Button>
          </Form.Item>
        </div>
      </Form>
    </ComponentLayout>
  );
}

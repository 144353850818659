import { BASE_URL } from '../config';

//* auth endpoints - |ALL USERS TYPES| <Admin, End-User>
export const USER_URL = `${BASE_URL}/api/v1/auth/user`;
export const REGISTER_URL = `${BASE_URL}/api/v1/auth/signup`;
export const LOGIN_URL = `${BASE_URL}/api/v1/auth/login`;
export const LOGOUT_URL = `${BASE_URL}/api/v1/auth/logout`;
export const GOOGLE_LOGIN_URL = (method) => `${BASE_URL}/api/v1/auth/${method}/google`;
export const FORGET_PASSWORD_URL = `${BASE_URL}/api/v1/auth/forget-password`;
export const RESET_PASSWORD_URL = `${BASE_URL}/api/v1/auth/reset-password`;

//* admin endpoints - |ADMIN|
export const USER_WAITING_LIST_URL = `${BASE_URL}/api/v1/admin/waiting-list`;
export const USER_APPROVED_LIST_URL = `${BASE_URL}/api/v1/admin/approved-list`;
export const USER_REJECTED_LIST_URL = `${BASE_URL}/api/v1/admin/rejected-list`;
export const USER_BANNED_LIST_URL = `${BASE_URL}/api/v1/admin/banned-list`;

export const ADD_USER_URL = `${BASE_URL}/api/v1/admin/add-user`;

export const APPROVE_USER_BY_ID_URL = (userId) => `${BASE_URL}/api/v1/admin/approve/${userId}`;
export const REJECT_USER_BY_ID_URL = (userId) => `${BASE_URL}/api/v1/admin/reject/${userId}`;
export const BLOCK_USER_BY_ID_URL = (userId) => `${BASE_URL}/api/v1/admin/block-user/${userId}`;
export const ACCEPT_PAYOUT_BY_ID_URL = (payoutId) => `${BASE_URL}/api/v1/admin/payouts/${payoutId}/approve`;
export const REJECT_PAYOUT_BY_ID_URL = (payoutId) => `${BASE_URL}/api/v1/admin/payouts/${payoutId}/reject`;
export const GET_DASHBOARD_SETTINGS = `${BASE_URL}/api/v1/admin/dashboard-settings`;
export const UPDATE_DASHBOARD_SETTINGS = `${BASE_URL}/api/v1/admin/edit-dashboard-settings`;
export const GET_USERS_URL = `${BASE_URL}/api/v1/admin/users`;
export const EDIT_USER_PROFILE = `${BASE_URL}/api/v1/admin/edit-user-profile`;
export const GET_ADMIN_STATISTICS = (fromDate, toDate) => `${BASE_URL}/api/v1/admin/statistics?fromDate=${fromDate}&toDate=${toDate}`;
export const GET_SERVICES = `${BASE_URL}/api/v1/admin/get-services`;
export const ADD_NEWS = `${BASE_URL}/api/v1/admin/add-news`;
export const EDIT_NEWS = `${BASE_URL}/api/v1/admin/edit-news`;
export const DELETE_NEWS = (userId) => `${BASE_URL}/api/v1/admin/newslist/${userId}`;
export const GET_USER_STATISTICS = (userId) => `${BASE_URL}/api/v1/user/statistics/${userId}`;
export const UPDATE_VAT_STATUS = `${BASE_URL}/api/v1/admin/change-vat-status`;
export const GET_VAT_STATUS = (contentId) => `${BASE_URL}/api/v1/admin/get-vat-status/${contentId}`;

// * content
export const GET_PAGINATED_CONTENT = ({ limit, page }) => `${BASE_URL}/api/v1/content?limit=${limit}&page=${page}`;

export const GET_CONTENT_BY_ID = (contentId) => `${BASE_URL}/api/v1/content?limit=1&page=1&id=${contentId}`;

export const GET_POSSIBLE_CONTENTS = (title) => `${BASE_URL}/api/v1/content?limit=1&page=1&title=${title}`;
export const GET_MATCHED_CONTENT = `${BASE_URL}/api/v1/content/get-matched-content`;

export const MATCH_USER_CONTENT = `${BASE_URL}/api/v1/content/match-user-content`;
export const EDIT_CONTENT_API = `${BASE_URL}/api/v1/admin/edit-content-data`;
export const ADD_OTHER_REVENUE = `${BASE_URL}/api/v1/content/add-other-revenue`;
export const ADD_COMEDIAN_CONTENT = `${BASE_URL}/api/v1/content/add-comedian-content`;
export const ADD_NEW_CONTENT = `${BASE_URL}/api/v1/content/add-content`;

//* user
export const CHANGE_PASSWORD_URL = `${BASE_URL}/api/v1/user/change-password`;
export const GET_STATISTICS = (userId) => `${BASE_URL}/api/v1/user/statistics/${userId}`;
export const STATISTICS_DATA_URL = `${BASE_URL}/api/v1/user/statistics`;

export const EDIT_PROFILE_URL = `${BASE_URL}/api/v1/user/edit-profile`;
export const GET_CONTENT_REPORT = (contentId) => `${BASE_URL}/api/v1/user/get-content-reports/${contentId}`;
export const GET_COMEDIAN_CONTENT = ({ limit, page }) => `${BASE_URL}/api/v1/content/get-comedian-contents?limit=${limit}&page=${page}`;
export const GET_USER_PROFILE = (userId) => `${BASE_URL}/api/v1/user/user-data/${userId}`;
export const GET_USER_CONTENT_URL = ({ limit, page }) => `${BASE_URL}/api/v1/user/contents?limit=${limit}&page=${page}`;
export const GET_ACCOUNTANT_CONTENTS = ({ limit, page }) => `${BASE_URL}/api/v1/accountant/contents?limit=${limit}&page=${page}`;
export const GET_NEWS = `${BASE_URL}/api/v1/user/get-news`;
export const GET_COMEDIAN_LIST = `${BASE_URL}/api/v1/user/get-comedian-list`;
export const ADD_COMEDIAN = `${BASE_URL}/api/v1/user/add-comedian`;
export const FINANCIAL_STATUS_URL = `${BASE_URL}/api/v1/user/financial-information`;
export const GET_CURRENT_WATCHED = (contentId) => `${BASE_URL}/api/v1/user/current-watched-time/${contentId}`;
export const GET_CURRENT_WATCHED_COMEDIAN = (contentId) => `${BASE_URL}/api/v1/comedian/current-watched-time-comedian/${contentId}`;
export const GET_COMEDIAN_CONTENT_REPORT = (contentId) => `${BASE_URL}/api/v1/comedian/get-comedian-content-reports/${contentId}`;
export const SHARE_CONTENT_BY_COMEDIAN = `${BASE_URL}/api/v1/content/upload-content`;
export const CHOOSE_PAYMENT_METHOD_URL = `${BASE_URL}/api/v1/user/payment-method`;

// * navigation endpoints
export const MATCH_CONTENT_ENDPOINT = (id) => `/dashboard/contentlist/matchcontent/${id}`;
export const EDIT_USER_ENDPOINT = (id) => `/dashboard/userlist/edituser/${id}`;
export const USER_STATISTICS_ENDPOINT = (id) => `/dashboard/userlist/statistics/${id}`;
export const USER_CONTENT_ENDPOINT = (contentId) => `/dashboard/yourvideos/${contentId}`;
export const CONTENT_LIST_URL = '/dashboard/contentlist';
export const UPDATE_LIST_URL = '/dashboard/changecontent';
export const EDIT_CONTENT = (id) => `/dashboard/contentlist/edit-content-data/${id}`;
export const EDIT_NEWS_ENDPOINT = (id) => `/dashboard/newslist/editnews/${id}`;

export const PAYOUTS_URL = `${BASE_URL}/api/v1/user/payouts`;
export const GET_ADMIN_PAYOUTS = `${BASE_URL}/api/v1/admin/payouts?sort=desc`;
export const ADD_STRIPE_ACCOUNT = `${BASE_URL}/api/v1/admin/set-user-stripe-account`;
export const GENERATE_REPORT = `${BASE_URL}/api/v1/admin/report`;
export const CHANGE_CONTENT_OWNER = `${BASE_URL}/api/v1/content/change-content-owner`;
export const ACCOUNTANT_PAYOUTS_URL = `${BASE_URL}/api/v1/accountant/payouts`;
export const CHANGE_USER_CONTENT_VAT_STATUS = `${BASE_URL}/api/v1/user/change-user-content-vat-status`;

import {
  Form, Button, AutoComplete, message,
} from 'components/AntDesign';
import { useAppDispatch } from 'hooks';
import {
  changeContentOwner,
  getApprovedList,
  getMatchedContent,
} from 'store/admin/thunk';

import { useState, useEffect } from 'react';
import { validationMessages } from 'utils';
import { getContentById } from 'store/content/thunk';

function ChangeContentOwner() {
  const [users, setUsers] = useState({});
  const [value, setValue] = useState('');
  const [oldUserId, setOldUserId] = useState(0);
  const [newUserId, setNewUserId] = useState(0);
  const [contents, setContents] = useState({});
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const payload = await dispatch(getApprovedList()).unwrap();
      setUsers(payload);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await dispatch(getMatchedContent()).unwrap();
      setContents(data);
    };
    fetchData();
  }, []);
  const onFinish = async () => {
    const { id } = contents.find((content) => content.title === value);
    const payload = await dispatch(getContentById(id)).unwrap();
    const { userId } = payload;
    if (userId !== oldUserId) {
      message.error('The content does not belong to the old owner');
      return;
    }
    dispatch(changeContentOwner({
      id, oldUserId, newUserId,
    }));
  };
  const contentsOptions = Object.values(contents).map((option) => ({
    value: option.title,
    id: option.id,
  }));
  const usersOptions = Object.values(users).map((option) => ({
    value: option.email,
    id: option.id,
  }));
  const onChange = (text) => {
    setValue(text);
  };
  const onChangeOldUserId = (text) => {
    const { id } = usersOptions.find((user) => user.value === text);
    setOldUserId(id);
  };
  const onChangeNewUserId = (text) => {
    const { id } = usersOptions.find((user) => user.value === text);
    setNewUserId(id);
  };

  const filterOption = {
    filterOption: (inputValue, option) => option?.value?.toUpperCase()
      .indexOf(inputValue.toUpperCase()) !== -1,
  };

  return (
    <>
      <div className="heading-nav">
        <div className="heading-icon" />
        <div className="title-heading">Change the content Owner</div>
      </div>

      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        autoComplete="off"
        className="my-form"
        layout="vertical"
        requiredMark={false}
      >
        <Form.Item label="The Content Name" name="id">
          <AutoComplete
            size="large"
            value={value}
            options={contentsOptions}
            onChange={onChange}
            placeholder="Search content name"
            filterOption={filterOption}
          />
        </Form.Item>
        <Form.Item
          label="Enter the old Owner email"
          name="oldUserId"
          rules={validationMessages.userId}
        >
          <AutoComplete
            size="middle"
            options={usersOptions}
            value={oldUserId}
            placeholder="Search old owner email"
            onChange={onChangeOldUserId}
          />
        </Form.Item>
        <Form.Item
          label="The New Owner email"
          name="newUserId"
          rules={validationMessages.newUserId}
        >
          <AutoComplete
            size="middle"
            options={usersOptions}
            value={newUserId}
            onChange={onChangeNewUserId}
            placeholder="Search new owner email"
          />

        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="form-button"
          >
            Update Content
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default ChangeContentOwner;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Space, Button } from 'components/AntDesign';
import PDFModal from './PDFModal'; // Import your PDFModal component

function InvoiceModal({ invoice }) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <Space size="middle">
      <Button onClick={showModal}>View Invoice</Button>
      <PDFModal
        visible={isModalVisible}
        onClose={closeModal}
        invoice={invoice}
      />
    </Space>
  );
}

InvoiceModal.propTypes = {
  invoice: PropTypes.string.isRequired,
};

export default InvoiceModal;

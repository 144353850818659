import { LinkComedianContent } from 'components';
import { ComponentLayout } from 'layouts';

function LinkContentPage() {
  return (
    <ComponentLayout>

      <LinkComedianContent />
    </ComponentLayout>
  );
}

export default LinkContentPage;

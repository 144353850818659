import {
  Alert,
  Button, Form, Input, Radio,
} from 'components/AntDesign';
import { useAppDispatch, useAppSelector } from 'hooks';
import { choosepaymentmethod } from 'store/user/thunk';
import './style.css';
import { useState } from 'react';
import { validationMessages } from 'utils';
import { Link } from 'react-router-dom';

function PaymentSystem() {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.checkAuth);
  const [paymentMethod, setPaymentMethod] = useState(user.preferredPayoutMethod);
  const [vatPayer, setVatPayer] = useState(false);
  const [bankAccountNumber, setBankAccountNumber] = useState('');
  const [bankSortCode, setBankSortCode] = useState('');
  const [accountName, setAccountName] = useState('');
  const [ibanNumber, setIbanNumber] = useState('');
  const [bankName, setBankName] = useState('');
  const [bankAddress, setBankAddress] = useState('');
  const [swiftCode, setSwiftCode] = useState('');
  const [bicCode, setBicCode] = useState('');
  const [form] = Form.useForm();

  const onFinish = async () => {
    const data = {
      userId: user.id,
      preferredPayoutMethod: paymentMethod,
      vatPayer,
    };
    if (paymentMethod === 'bank') {
      data.bankAccountNumber = bankAccountNumber;
      data.bankSortCode = bankSortCode;
      data.actName = accountName;
    } else if (paymentMethod === 'international') {
      data.accountHolderName = accountName;
      data.bankAccountNumber = bankAccountNumber;
      data.bankName = bankName;
      data.ibanNumber = ibanNumber;
      data.swiftCode = swiftCode;
      data.bicCode = bicCode;
      data.bankAddress = bankAddress;
    }
    try {
      dispatch(choosepaymentmethod(data));
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleVatOptionChange = (e) => {
    const { value } = e.target;
    setVatPayer(value === 'yes');
  };

  return (
    <Form
      form={form}
      name="basic"
      onFinish={onFinish}
      requiredMark={false}
      autoComplete="off"
      className="stripe-form"
    >
      {user.preferredPayoutMethod === null ? (
        <>
          <div className="paymentSystem">
            <Radio.Group onChange={handlePaymentMethodChange} value={paymentMethod}>
              <Radio.Button value="bank">Bank Transfer</Radio.Button>
              <Radio.Button value="international">International Bank Transfer</Radio.Button>
            </Radio.Group>
          </div>
          {paymentMethod === 'bank' && (
            <div className="paymentMethod">
              <Form.Item name="accountName" className="paymentInput" rules={validationMessages.bankAccountHolderName}>
                <Input placeholder="Account Holder Name" className="paymentInput" onChange={(e) => setAccountName(e.target.value)} type="text" />
              </Form.Item>
              <Form.Item name="bankAccountNumber" className="paymentInput" rules={validationMessages.bankAccountNumber}>
                <Input placeholder="Bank Account Number" className="paymentInput" onChange={(e) => setBankAccountNumber(e.target.value)} />
              </Form.Item>
              <Form.Item name="bankSortCode" className="paymentInput" rules={validationMessages.bankSortCode}>
                <Input placeholder="Bank Sort Code - 123456" className="paymentInput" onChange={(e) => setBankSortCode(e.target.value)} />
              </Form.Item>
              <Form.Item name="vatPayer" className="paymentInput">
                <p>Are you a VAT payer?</p>
                <Radio.Group onChange={handleVatOptionChange}>
                  <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                  <Radio value="manual">I&apos;ll choose on a &apos;per show&apos; basis.</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          )}
          {paymentMethod === 'international' && (
            <div className="paymentMethod">
              <Form.Item name="bankAccountNumber" className="paymentInput" rules={validationMessages.bankAccountNumber}>
                <Input placeholder="Bank Account Number" className="paymentInput" onChange={(e) => setBankAccountNumber(e.target.value)} />
              </Form.Item>
              <Form.Item name="accountName" className="paymentInput" rules={validationMessages.bankAccountHolderName}>
                <Input placeholder="Account Holder Name" className="paymentInput" onChange={(e) => setAccountName(e.target.value)} type="text" />
              </Form.Item>
              <Form.Item name="bankAddress" className="paymentInput" rules={validationMessages.bankAddress}>
                <Input placeholder="Account Holder Address" className="paymentInput" onChange={(e) => setBankAddress(e.target.value)} />
              </Form.Item>
              <Form.Item name="bankName" className="paymentInput" rules={validationMessages.bankName}>
                <Input placeholder="Bank Name" className="paymentInput" onChange={(e) => setBankName(e.target.value)} />
              </Form.Item>
              <Form.Item name="ibanNumber" className="paymentInput" rules={validationMessages.ibanNumber}>
                <Input placeholder="IBAN Number" className="paymentInput" onChange={(e) => setIbanNumber(e.target.value)} />
              </Form.Item>
              <Form.Item name="swiftCode" className="paymentInput" rules={validationMessages.swiftCode}>
                <Input placeholder="Swift Code" className="paymentInput" onChange={(e) => setSwiftCode(e.target.value)} />
              </Form.Item>
              <Form.Item name="bicCode" className="paymentInput" rules={validationMessages.bicCode}>
                <Input placeholder="BIC Code" className="paymentInput" onChange={(e) => setBicCode(e.target.value)} />
              </Form.Item>
            </div>
          )}
          <Form.Item noStyle>
            <Button className="form-button" type="primary" htmlType="submit">
              ADD PAYOUT METHOD
            </Button>
          </Form.Item>
        </>
      ) : (
        <>
          {user.preferredPayoutMethod === 'bank' && (
            <div>
              <Form.Item name="email">
                <Input placeholder={`Payment Method : ${user.preferredPayoutMethod}`} type="email" disabled />
              </Form.Item>
              <Form.Item name="accountName">
                <Input placeholder={`Account Holder Name : ${user.accountHolderName}`} type="text" disabled />
              </Form.Item>
              <Form.Item name="bankAccountNumber">
                <Input placeholder={`Bank Account Number : ${user.accountNumber}`} type="text" disabled />
              </Form.Item>
              <Form.Item name="bankSortCode">
                <Input placeholder={`Bank Sort Code : ${user.sortCode}`} type="text" disabled />
              </Form.Item>
              {user.vatPayer === false && (
                <Form.Item name="vatPayer">
                  <Link to="/dashboard/contentVatChange">
                    Change VAT Status For Each Piece Of Content
                  </Link>
                </Form.Item>
              )}
              <Alert
                style={{ marginTop: '5px', marginBottom: '10px' }}
                message="Please contact us if you wish to change your payment method."
                type="info"
                showIcon
              />
            </div>
          )}
          {user.preferredPayoutMethod === 'stripe' && (
            <div>
              You have already chosen your payment method. -
              {' '}
              {user.preferredPayoutMethod}
              {' '}
              <br />
              Stripe Account ID -
              {' '}
              {user.stripeAccountId}
              <br />
            </div>
          )}
          {user.preferredPayoutMethod === 'international' && (
            <div>
              <Form.Item name="bankAccountNumber">
                <Input placeholder={`Bank Account Number : ${user.accountNumber}`} type="text" disabled />
              </Form.Item>
              <Form.Item name="accountName">
                <Input placeholder={`Account Holder Name : ${user.accountHolderName}`} type="text" disabled />
              </Form.Item>
              <Form.Item name="bankAddress">
                <Input placeholder={`Account Holder Address : ${user.bankAddress}`} type="text" disabled />
              </Form.Item>
              <Form.Item name="bankName">
                <Input placeholder={`Bank Name : ${user.bankName}`} type="text" disabled />
              </Form.Item>
              <Form.Item name="ibanNumber">
                <Input placeholder={`IBAN Number : ${user.ibanNumber}`} type="text" disabled />
              </Form.Item>
              <Form.Item name="swiftCode">
                <Input placeholder={`Swift Code : ${user.swiftCode}`} type="text" disabled />
              </Form.Item>
              <Form.Item name="bicCode">
                <Input placeholder={`BIC Code : ${user.bicCode}`} type="text" disabled />
              </Form.Item>
              <Alert
                style={{ marginTop: '5px', marginBottom: '10px' }}
                message="Please contact us if you wish to change your payment method."
                type="info"
                showIcon
              />
            </div>
          )}
        </>
      )}
    </Form>
  );
}

export default PaymentSystem;

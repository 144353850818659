import PropsTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Card } from 'components/AntDesign';
import { EDIT_CONTENT } from 'shared/constants/endpoints';
import '../ContentList/style.css';

function VideoCardEdit({ id, title, permalink }) {
  const navigate = useNavigate();

  return (
    <Card
      onClick={() => navigate(EDIT_CONTENT(id))}
      hoverable
    >
      <div className="contents-list__video-card">
        <div className="image-wrapper">
          <img src={permalink} alt={title} style={{ width: '100px', height: '100px' }} />
        </div>
        <div className="info">
          <h3 className="title">{title}</h3>
        </div>
      </div>
    </Card>
  );
}

VideoCardEdit.propTypes = {
  id: PropsTypes.number.isRequired,
  title: PropsTypes.string.isRequired,
  permalink: PropsTypes.string.isRequired,
};

export default VideoCardEdit;

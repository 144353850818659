import { Route } from 'react-router-dom';
import { Button, Popconfirm } from 'components/AntDesign';
import PayoutModal from 'components/Dashboard/AdminPayout/Modal';
import { icons } from 'shared/constants';
import { formatDateTime } from 'utils';
import InvoiceModal from 'components/Dashboard/AdminPayout/InvoiceModal';
import { Image } from 'shared/components';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '20%',
  },

  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Registration Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (dateInISO) => formatDateTime(dateInISO),
  },
  {
    title: 'Role',
    dataIndex: 'roleName',
    key: 'roleName',
  },
];

const statisticsColumns = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'name',
  },
  {
    title: 'Watched Time',
    dataIndex: 'watchedSeconds',
    key: 'watchedSeconds',
  },
  {
    title: 'Share Revenue',
    dataIndex: 'owedRevenue',
    key: 'owedRevenue',
  },
  {
    title: 'Other Revenue',
    dataIndex: 'details',
    key: 'details',
  },
  {
    title: 'Watched Time For Current Quarter',
    dataIndex: 'currentWatchedSeconds',
    key: 'currentWatchedSeconds',
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    key: 'notes',
  },
];
const adminUsersStatisticsColumns = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'name',
  },
  {
    title: 'Watched Time',
    dataIndex: 'watchedSeconds',
    key: 'watchedSeconds',
  },
  {
    title: 'Total Accumulated Revenue',
    dataIndex: 'owedAccRevenue',
    key: 'owedAccRevenue',
  },
  {
    title: 'Last Quarter Revenue',
    dataIndex: 'owedRevenue',
    key: 'owedRevenue',
  },
  {
    title: 'Other Revenue',
    dataIndex: 'details',
    key: 'details',
  },
  {
    title: 'Watched Time For Current Quarter',
    dataIndex: 'currentWatchedSeconds',
    key: 'currentWatchedSeconds',
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    key: 'notes',
  },
  {
    title: 'Reports',
    dataIndex: 'reports',
    key: 'reports',
  },
];
const comedianstatisticsColumns = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'name',
  }, {
    title: 'Watched Time',
    dataIndex: 'watchedSeconds',
    key: 'watchedSeconds',
  },
  {
    title: 'Watched Time For Current Quarter',
    dataIndex: 'currentWatchedSeconds',
    key: 'currentWatchedSeconds',
  },
];
const historyColumns = [
  {
    title: 'Quarter Number',
    dataIndex: 'reportId',
    key: 'reportId',
  },
  {
    title: 'Content Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Accumulated Watched Time',
    dataIndex: 'watchedSeconds',
    key: 'watchedSeconds',
  },
  {
    title: 'Total Revenue',
    dataIndex: 'owedRevenue',
    key: 'owedRevenue',
  },
  {
    title: 'Other Details',
    dataIndex: 'details',
    key: 'details',

  },
  {
    title: 'Watched Time For Current Quarter',
    dataIndex: 'currentWatchedSeconds',
    key: 'currentWatchedSeconds',
  },
];

const videoListColumns = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'name',
  },
  {
    title: 'Launch Date',
    dataIndex: 'launchDate',
    key: 'launchDate',
  },
  {
    title: 'Show Length',
    dataIndex: 'runtime',
    key: 'runtime',
  },
  // {
  //   title: 'Accumulated Watched Time',
  //   dataIndex: 'watchedSeconds',
  //   key: 'watchedSeconds',
  // },
  {
    title: 'Total Revenue',
    dataIndex: 'owedAccRevenue',
    key: 'owedAccRevenue',
  },
];

const comedioanVideoListColumns = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'name',
  },
  {
    title: 'Launch Date',
    dataIndex: 'launchDate',
    key: 'launchDate',
  },
  {
    title: 'Run Time',
    dataIndex: 'runtime',
    key: 'runtime',
  },
  {
    title: 'Accumulated Watched Time',
    dataIndex: 'watchedSeconds',
    key: 'watchedSeconds',
  },

];
const latestReportColumns = [
  {
    title: 'Watched Time From',
    dataIndex: 'watchTimeFrom',
    key: 'watchTimeFrom',
  },
  {
    title: 'Watched Time To',
    dataIndex: 'watchTimeTo',
    key: 'watchTimeTo',
  },
  {
    title: 'Total Revenue',
    dataIndex: 'totalRevenue',
    key: 'totalRevenue',
  },
  {
    title: 'New Total Watched Time',
    dataIndex: 'overallWatchedSeconds',
    key: 'overallWatchedSeconds',
  },
];

const adminPayoutColumns = [
  { title: 'Payout ID', dataIndex: 'id', key: 'id' },
  { title: 'User ID', dataIndex: 'userId', key: 'userId' },
  { title: 'User Name', dataIndex: 'name', key: 'name' },
  {
    title: 'Payout Status',
    dataIndex: 'payoutStatus',
    key: 'payoutStatusId',
    render: (payoutStatus) => (payoutStatus ? payoutStatus.name : ''),
  },
  { title: 'Amount', dataIndex: 'amount', key: 'amount' },
  { title: 'Requested At', dataIndex: 'createdAt', key: 'Requestedat' },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    render: (text, payout) => {
      if (payout.payoutStatusId === 1) {
        return <PayoutModal payout={payout} />;
      }
      return null;
    },
  },
  {
    title: 'Invoice',
    dataIndex: 'invoice',
    key: 'invoice',
    render: (text, payout) => {
      if (payout.payoutStatusId === 2 && payout.invoice !== null) {
        return (
          <InvoiceModal invoice={payout.invoice} />
        );
      }
      return null;
    },
  },
];

const {
  Content, Earnings, Payouts, PendingUsers, AllUsers,
} = icons;

const AdminGeneralColumns = (
  count,
  earning,
  Payout,
  allUsers,
  pendingUsers,
  totalPayouts,
  paidPayouts,
) => {
  const AdminGeneralViewColumns = [
    {
      icon: Content,
      title: 'Content',
      value: count,
    },
    {
      icon: Earnings,
      title: 'Subscription Earnings',
      value: earning,
    },
    {
      icon: Payouts,
      title: 'Total Earnings After 50%',
      value: totalPayouts,
    },
    {
      icon: Payouts,
      title: 'Paid Payouts',
      value: paidPayouts,
    },
    {
      icon: Payouts,
      title: 'Uncliamed Payouts',
      value: (totalPayouts - paidPayouts).toFixed(2),
    },
    {
      icon: Payouts,
      title: 'Payout Requests',
      value: Payout,
    },
    {
      icon: AllUsers,
      title: 'All Users',
      value: allUsers,
    },
    {
      icon: PendingUsers,
      title: 'Pending Users',
      value: pendingUsers,
    },

  ];
  return AdminGeneralViewColumns;
};
const payoutColumns = (handlePayout) => [
  {
    title: 'Payout ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Payout Status',
    dataIndex: 'payoutStatus',
    key: 'payoutStatusId',
    render: (payoutStatus) => (payoutStatus ? payoutStatus.name : ''),
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'Requested At',
    dataIndex: 'createdAt',
    key: 'Requestedat',
  },
  {
    title: 'Rejection Reason If Any',
    dataIndex: 'rejectionReason',
    key: 'rejectionReason',
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    render: (text, record) => {
      if (record.payoutStatusId === 1) {
        return (
          <Popconfirm
            title="Are you sure to cancel this payout?"
            onConfirm={() => handlePayout()}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" danger>
              Cancel
            </Button>
          </Popconfirm>
        );
      }
      return null;
    },
  },
];
const payoutHistoryColumns = [
  {
    title: 'Payout ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Payout Status',
    dataIndex: 'payoutStatus',
    key: 'payoutStatusId',
    render: (payoutStatus) => (payoutStatus ? payoutStatus.name : ''),
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'Requested At',
    dataIndex: 'createdAt',
    key: 'Requestedat',
  },
  {
    title: 'Rejection Reason If Any',
    dataIndex: 'rejectionReason',
    key: 'rejectionReason',
  },
  {
    title: 'Invoice',
    dataIndex: 'invoice',
    key: 'invoice',
    render: (text, payout) => {
      if (payout.payoutStatusId === 2 && payout.invoice !== null) {
        return (
          <InvoiceModal invoice={payout.invoice} />
        );
      }
      return null;
    },
  },
];
const comedianListColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Total Revenue',
    dataIndex: 'totalRevenue',
    key: 'totalRevenue',
  },
  {
    title: 'Paid Revenue',
    dataIndex: 'paidRevenue',
    key: 'paidRevenue',
  },
];
const uploadedContentColumns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'User Id',
    dataIndex: 'userId',
    key: 'userId',
  },
  {
    title: 'Link',
    dataIndex: 'link',
    key: 'link',
  },
  {
    title: 'Text',
    dataIndex: 'text',
    key: 'text',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
];

const newsManagementColumns = [

  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Content Summary',
    dataIndex: 'newsContent',
    key: 'newsContent',
    render: (text) => text.substring(0, 100),
  },
  {
    title: 'Image',
    dataIndex: 'image',
    key: 'image',
    render: (image) => <Image src={image} className="news-image" />,
  },

];

const accountantVideoListColumns = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'name',
  },
  {
    title: 'Total Accumulated Revenue',
    dataIndex: 'owedAccRevenue',
    key: 'owedAccRevenue',
  },
  {
    title: 'This Quarter Revenue',
    dataIndex: 'owedRevenue',
    key: 'owedRevenue',
  },

];
const routesBasedOnRole = (role, routes, Index) => (
  <>
    {routes.map((route, index) => (
      <Route key={route.key || index} path={route.path} element={<route.Component />} />
    ))}
    <Route index element={<Index />} />
  </>
);

export {
  columns,
  statisticsColumns,
  latestReportColumns,
  AdminGeneralColumns,
  videoListColumns,
  payoutColumns,
  adminPayoutColumns,
  payoutHistoryColumns,
  routesBasedOnRole,
  historyColumns,
  comedianListColumns,
  comedianstatisticsColumns,
  comedioanVideoListColumns,
  uploadedContentColumns,
  newsManagementColumns,
  accountantVideoListColumns,
  adminUsersStatisticsColumns,
};

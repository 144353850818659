import { Card } from 'components/AntDesign';
import { DetailedView, StatisticsGrid } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ComponentLayout } from 'layouts';
import { useEffect, useState } from 'react';
import { Loader, MainTitle } from 'shared/components';
import { getComedianContent } from 'store/content/thunk';
import { icons } from 'shared/constants';

function ComedianStatistics() {
  const dispatch = useAppDispatch();
  const { user, isLoading } = useAppSelector((state) => state.checkAuth);
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const {
    Content,
  } = icons;
  useEffect(() => {
    if (user.id) {
      const fetchData = async () => {
        const response = await dispatch(getComedianContent({ page: 1, limit: 100 })).unwrap();
        setRows(response?.userContents.rows || []);
        setCount(response?.userContents.rows.length || 0);
      };
      fetchData();
    }
  }, [dispatch, user.id]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ComponentLayout title="Statistics">
      <MainTitle title="General View" />
      <Card className="general-view-statistics" style={{ marginBottom: '20px' }}>
        <StatisticsGrid icon={Content} title="Contents" value={count} key={Content} />
      </Card>
      <DetailedView rows={rows} listToSee="comedianstatistics" />
    </ComponentLayout>
  );
}

export default ComedianStatistics;

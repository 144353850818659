import { useAppDispatch } from 'hooks';
import {
  Input, Button, Form, Select,
} from 'components/AntDesign';
import { ComponentLayout } from 'layouts';
import { validationMessages } from 'utils';
import { addComedian } from 'store/user/thunk';
import { ROLES } from 'shared/constants';
import './style.css';

export default function AddComedian() {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { Option } = Select;

  const onFinish = async (values) => {
    const { name, type } = values;

    const email = values.email.toLowerCase();
    dispatch(addComedian({ name, email, type }));

    form.resetFields();
  };

  return (
    <ComponentLayout heading="Add Client">
      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
      >
        <div className="add-new-user-form-form-f">

          <Form.Item
            name="name"
            rules={validationMessages.name}
          >
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item
            name="email"
            rules={validationMessages.email}
          >
            <Input placeholder="Email" type="email" />
          </Form.Item>
          <Form.Item
            name="type"
          >
            <Select placeholder="Select Type">
              <Option value={ROLES.COMEDIAN}>Comedian</Option>
              <Option value={ROLES.ACCOUNTANT}>Accountant</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle>
            <Button className="form-button" type="primary" htmlType="submit">
              Create New Account
            </Button>
          </Form.Item>
        </div>
      </Form>
    </ComponentLayout>
  );
}

import { Table } from 'antd';
import { useAppDispatch } from 'hooks';
import { ComponentLayout } from 'layouts';
import { useEffect, useState } from 'react';
import { uploadedContentColumns } from 'shared/objects/Users';
import { getServices } from 'store/admin/thunk';

function ServicesList() {
  const [rows, setRows] = useState([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const { payload } = await dispatch(getServices());
      setRows(payload);
    };
    fetchData();
  }, [dispatch]);
  return (
    <ComponentLayout title="Uploaded Contents">
      <Table
        locale={{ emptyText: 'No Uploaded Contents' }}
        columns={uploadedContentColumns}
        dataSource={rows}
        pagination={{ pageSize: 5 }}
        scroll={{ x: 500 }}
      />
    </ComponentLayout>
  );
}

export default ServicesList;

import { ChangeVATStatus } from 'components';
import { ComponentLayout } from 'layouts';
import React from 'react';

function ChangeVATStatusPage() {
  return (
    <ComponentLayout title="Change VAT Status">
      <ChangeVATStatus />
    </ComponentLayout>
  );
}

export default ChangeVATStatusPage;

import { useEffect, useState } from 'react';
import { DetailedView } from 'components';
import { ComponentLayout } from 'layouts';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getComedianContent } from 'store/content/thunk';

function ComedianVideoList() {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.checkAuth);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (user.id) {
      const fetchData = async () => {
        const response = await dispatch(getComedianContent({ page: 1, limit: 100 })).unwrap();
        setRows(response?.userContents.rows || []);
      };
      fetchData();
    }
  }, [dispatch, user.id]);

  return (
    <ComponentLayout title="Video List" heading="Video List">
      <DetailedView rows={rows} listToSee="comedianVideoList" />
    </ComponentLayout>
  );
}

export default ComedianVideoList;

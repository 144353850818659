/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { ComponentLayout } from 'layouts';
import { useAppDispatch } from 'hooks';
import { getComedianContentReport } from 'store/user/thunk';
import { useParams } from 'react-router-dom';
import humanizeDuration from 'humanize-duration';
import { historyColumns } from 'shared/objects/Users';
import { Table, Tabs } from 'antd';
import {
  XAxis, YAxis, CartesianGrid, Legend, Tooltip, ComposedChart, Area,
} from 'recharts';

function ComedianVideoHistoryPage() {
  const dispatch = useAppDispatch();
  const [rows, setRows] = useState([]);
  const { videoId } = useParams();
  useEffect(() => {
    if (videoId) {
      const fetchData = async () => {
        const { payload } = await dispatch(getComedianContentReport(videoId));
        setRows(payload.rows);
      };
      fetchData();
    }
  }, []);

  const maxWatchedSeconds = Math.max(...rows.map((row) => row.watchedSeconds));
  const maxRevenue = Math.max(...rows.map((row) => row.owedRevenue));
  const maxRevFixed = maxRevenue.toFixed(2);
  const dataForChart = rows.map((row) => {
    const adjustedReportId = ((row.reportId - 1) % 4) + 1;
    const year = Math.floor((row.reportId - 1) / 4) % 2 === 0 ? 2023 : 2024;

    return {
      name: `Q${adjustedReportId} ${year}`,
      title: row.content.title,
      watchedSeconds: row.watchedSeconds,
      revenue: row.owedRevenue.slice(0, 5),
      reportId: row.reportId,
      watchedTime: humanizeDuration(row.watchedSeconds * 1000, {
        units: ['h', 'm', 's'],
      }),
    };
  });

  const items = [
    {
      label: 'Watched Time',
      key: '1',
      children: (
        <ComposedChart
          width={500}
          height={400}
          data={dataForChart}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis
            tickFormatter={(value) => {
              // Assuming `value` is the `reportId` directly:
              const adjustedReportId = ((value - 1) % 4) + 1;
              const year = Math.floor((value - 1) / 4) % 2 === 0 ? 2023 : 2024;
              return `Q${adjustedReportId} ${year}`;
            }}
            dataKey="reportId"
          />

          <YAxis domain={[0, maxWatchedSeconds]} />
          <Tooltip formatter={(value) => humanizeDuration(value * 1000, { units: ['h', 'm', 's'] })} />
          {' '}
          <Legend />
          <Area
            type="monotone"
            dataKey="watchedSeconds"
            stroke="#008a84"
            fill="#008a84"
            strokeWidth={2}
            dot={false}
            name="Watched Time"
          />

        </ComposedChart>
      ),
    },
    {
      label: 'Revenue',
      key: '2',
      children: (
        <ComposedChart
          width={500}
          height={400}
          data={dataForChart}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis
            dataKey="reportId"
            tickFormatter={(value) => `Q${value} 2023`}
          />
          <YAxis domain={[0, Number(maxRevFixed)]} />
          <Tooltip />
          <Legend />
          <Area
            type="monotone"
            dataKey="revenue"
            stroke="#13e0d5"
            fill="#13e0d5"
            strokeWidth={2}
            dot={false}
            name="Revenue"
          />

        </ComposedChart>
      ),
    },
  ];
  return (
    <ComponentLayout title="Video List" heading="Video List">
      <div className="video-history">

        <Table
          columns={historyColumns}
          dataSource={rows.map((row) => ({
            title: row.content.title,
            watchedSeconds: humanizeDuration(
              row.watchedSeconds * 1000,
              { units: ['h', 'm', 's'] },
            ),
            reportId: `Q${row.reportId} 2023`,
            key: row.id,

          }))}
          pagination={{ pageSize: 5 }}
          scroll={{ x: 500 }}
        />

        <Tabs
          defaultActiveKey="1"
          type="card"
          size="large"
          items={items}
        />
      </div>
    </ComponentLayout>

  );
}

export default ComedianVideoHistoryPage;

import {
  Card,
  Button, Popconfirm, Table,
} from 'components/AntDesign';
import { StatisticsGrid } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ComponentLayout } from 'layouts';
import { useEffect, useState } from 'react';
import { Loader, MainTitle } from 'shared/components';
import {
  askForAccountantPayout, cancelAccountantPayout, getAccountantPayouts, getAccountantContents
  ,
} from 'store/user/thunk';
import { icons } from 'shared/constants';
import { payoutColumns, payoutHistoryColumns } from 'shared/objects/Users';
import editedRows from 'utils/editedRows';

function AccountantStatistics() {
  const dispatch = useAppDispatch();
  const { user, isLoading } = useAppSelector((state) => state.checkAuth);
  const [payouts, setPayouts] = useState([]);
  const [pendingPayout, setPendingPayout] = useState([]);
  const [count, setCount] = useState(0);
  const [paidRevenue, setPaidRevenue] = useState(0);
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    if (user.id) {
      const fetchData = async () => {
        const { data } = await dispatch(
          getAccountantContents({ page: 1, limit: 100 }),
        ).unwrap();
        setCount(data?.userContents.count);
        setPaidRevenue(data?.agent.paidRevenue);
        setBalance(data?.agent.totalRevenue);
      };
      fetchData();
    }
  }, []);
  const askForMyPayout = () => {
    dispatch(askForAccountantPayout());
  };
  const cancelMyPayout = () => {
    const originalPayouts = [...pendingPayout];
    try {
      dispatch(cancelAccountantPayout());
      setPendingPayout([]);
    } catch (error) {
      setPendingPayout(originalPayouts);
    }
  };
  const paidRevenueFixed = Number(paidRevenue).toFixed(2);
  const balanceFixed = Number(balance).toFixed(2);
  const {
    Content, Balance, Payouts,
  } = icons;
  const arr = [
    {
      icon: Content,
      title: 'Content',
      value: count,
    },
    {
      icon: Balance,
      title: 'Balance To Claim',
      value: balanceFixed,
    },
    {
      icon: Payouts,
      title: 'Payouts To Date',
      value: paidRevenueFixed,
    },
  ];
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await dispatch(getAccountantPayouts()).unwrap();
      setPayouts(data.rows);
      setPendingPayout(data.rows.filter((row) => row.payoutStatusId === 1));
    };
    fetchData();
  }, [dispatch]);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>

      <ComponentLayout>
        <MainTitle title="General View" />
        <Card className="general-view-statistics" style={{ marginBottom: '20px' }}>
          {arr.map(({
            icon, value, title,
          }) => (
            <StatisticsGrid icon={icon} title={title} value={value} key={title} />
          ))}
        </Card>
      </ComponentLayout>
      <ComponentLayout>
        <div className="requestpayment">
          <MainTitle title="My Pending Payment" />
          <Popconfirm
            title="Are you sure that you want to request this payout?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => askForMyPayout()}
          >
            <Button type="primary" className="ask-payment-btn">
              Request Payout
            </Button>
          </Popconfirm>
        </div>
        <Table
          locale={{ emptyText: 'No Pending Payouts' }}
          columns={payoutColumns(cancelMyPayout)}
          dataSource={pendingPayout || []}
          pagination={{ pageSize: 5 }}
          scroll={{ x: 500 }}
          key="pending"
        />
      </ComponentLayout>
      <ComponentLayout heading="My Payment History">
        <Table
          locale={{ emptyText: 'No Pending Payouts' }}
          columns={payoutHistoryColumns}
          dataSource={editedRows(payouts)}
          pagination={{ pageSize: 5 }}
          scroll={{ x: 500 }}
          key="history"
        />
      </ComponentLayout>
    </>
  );
}

export default AccountantStatistics;

import { DetailedView } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ComponentLayout } from 'layouts';
import { useEffect, useState } from 'react';
import { getComedianList } from 'store/user/thunk';

function ComedianListPage() {
  const dispatch = useAppDispatch();
  const [rows, setRows] = useState([]);
  const { user } = useAppSelector((state) => state.checkAuth);

  useEffect(() => {
    if (user.id) {
      const fetchData = async () => {
        const response = await dispatch(getComedianList()).unwrap();
        setRows(response.comedians);
      };
      fetchData();
    }
  }, []);
  return (
    <ComponentLayout heading="Client Lists">
      <DetailedView rows={rows} listToSee="comedianList" />
    </ComponentLayout>
  );
}

export default ComedianListPage;

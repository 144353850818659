import {
  USER_WAITING_LIST_URL,
  USER_APPROVED_LIST_URL,
  USER_REJECTED_LIST_URL,
  USER_BANNED_LIST_URL,
  APPROVE_USER_BY_ID_URL,
  REJECT_USER_BY_ID_URL,
  ADD_USER_URL,
  GET_CONTENT_BY_ID,
  GET_PAGINATED_CONTENT,
  GET_USERS_URL,
  GET_POSSIBLE_CONTENTS,
  MATCH_USER_CONTENT,
  UPDATE_DASHBOARD_SETTINGS,
  GET_DASHBOARD_SETTINGS,
  EDIT_USER_PROFILE,
  GET_USER_PROFILE,
  GET_ADMIN_STATISTICS,
  GET_USER_STATISTICS,
  BLOCK_USER_BY_ID_URL,
  ACCEPT_PAYOUT_BY_ID_URL,
  REJECT_PAYOUT_BY_ID_URL,
  GET_ADMIN_PAYOUTS,
  ADD_STRIPE_ACCOUNT,
  GENERATE_REPORT,
  CHANGE_CONTENT_OWNER,
  GET_MATCHED_CONTENT,
  EDIT_CONTENT_API,
  ADD_OTHER_REVENUE,
  ADD_NEWS,
  ADD_NEW_CONTENT,
  EDIT_NEWS,
  GET_SERVICES,
  DELETE_NEWS,
  UPDATE_VAT_STATUS,
  GET_VAT_STATUS,
} from 'shared/constants/endpoints';
import { axiosCall } from '../utils';

export default {
  approveUser: async (userId) => axiosCall(APPROVE_USER_BY_ID_URL(userId), 'PATCH'),
  rejectUser: async (userId, rejectionReason) => axiosCall(REJECT_USER_BY_ID_URL(userId), 'PATCH', { rejectionReason }),
  createUser: async (userInfo) => axiosCall(ADD_USER_URL, 'POST', userInfo),
  blockUser: async (userId) => axiosCall(BLOCK_USER_BY_ID_URL(userId), 'PATCH'),
  getWaitingList: async () => axiosCall(USER_WAITING_LIST_URL, 'GET'),
  getApprovedList: async () => axiosCall(USER_APPROVED_LIST_URL, 'GET'),
  getRejectedList: async () => axiosCall(USER_REJECTED_LIST_URL, 'GET'),
  getBannedList: async () => axiosCall(USER_BANNED_LIST_URL, 'GET'),
  getContentById: async (contentId) => axiosCall(GET_CONTENT_BY_ID(contentId), 'GET'),
  getUsers: async () => axiosCall(GET_USERS_URL, 'GET'),
  getPaginatedContent: async ({ limit, page }) => axiosCall(GET_PAGINATED_CONTENT({ limit, page }), 'GET'),
  getPossibleContents: async (title) => axiosCall(GET_POSSIBLE_CONTENTS(title), 'GET'),
  matchUserContent: async (payload) => axiosCall(MATCH_USER_CONTENT, 'PATCH', payload),
  updateDashboardSettings: async (payload) => axiosCall(UPDATE_DASHBOARD_SETTINGS, 'PATCH', payload),
  geteDashboardSettings: async () => axiosCall(GET_DASHBOARD_SETTINGS, 'GET'),
  editUserProfile: async (userInfo) => axiosCall(EDIT_USER_PROFILE, 'PATCH', userInfo),
  getUserProfile: async (userId) => axiosCall(GET_USER_PROFILE(userId), 'GET'),
  getAdminStatistics: async (fromDate, toDate) => axiosCall(GET_ADMIN_STATISTICS(fromDate, toDate), 'GET'),
  getUserStatistics: async (userId) => axiosCall(GET_USER_STATISTICS(userId), 'GET'),
  acceptPayout: async (payoutId) => axiosCall(ACCEPT_PAYOUT_BY_ID_URL(payoutId), 'PATCH'),
  rejectPayout: async (payoutId, rejectionReason) => axiosCall(REJECT_PAYOUT_BY_ID_URL(payoutId), 'PATCH', { rejectionReason }),
  getAdminPayouts: async () => axiosCall(GET_ADMIN_PAYOUTS, 'GET'),
  addStripeAccount: async (payload) => axiosCall(ADD_STRIPE_ACCOUNT, 'PUT', payload),
  generateReport: async (payload) => axiosCall(GENERATE_REPORT, 'PUT', payload),
  changeContentOwner: async (payload) => axiosCall(CHANGE_CONTENT_OWNER, 'PATCH', payload),
  getMatchedContent: async () => axiosCall(GET_MATCHED_CONTENT, 'GET'),
  updateContent: async (payload) => axiosCall(EDIT_CONTENT_API, 'PATCH', payload),
  addOtherRevenue: async (payload) => axiosCall(ADD_OTHER_REVENUE, 'PATCH', payload),
  addNews: async (payload) => axiosCall(ADD_NEWS, 'POST', payload),
  addNewContent: async (payload) => axiosCall(ADD_NEW_CONTENT, 'POST', payload),
  editNews: async (payload) => axiosCall(EDIT_NEWS, 'PATCH', payload),
  getServices: async () => axiosCall(GET_SERVICES, 'GET'),
  deleteNews: async (newsId) => axiosCall(DELETE_NEWS(newsId), 'DELETE'),
  updateVatStatus: async (payload) => axiosCall(UPDATE_VAT_STATUS, 'PATCH', payload),
  getVatStatus: async (contentId) => axiosCall(GET_VAT_STATUS(contentId), 'GET'),
};

import { ComponentLayout } from 'layouts';

import { Input, Button, Form } from 'components/AntDesign';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Image, Loader } from 'shared/components';
import { validationMessages } from 'utils';
import { getNews } from 'store/user/thunk';
import { useParams } from 'react-router-dom';
import { editNews } from 'store/admin/thunk';
import ImageUploader from './uploadImage';

function EditNews() {
  const dispatch = useAppDispatch();
  const { newsId } = useParams();
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [form] = Form.useForm();
  const [image, setImage] = useState('');
  const [userId, setUserId] = useState('');
  const [oldImage, setOldImage] = useState('');

  const onFinish = async (values) => {
    const { title, newsContent, link } = values;
    const newsUpdatedInfo = {
      id: newsId,
      ...(title && { title }),
      ...(newsContent && { newsContent }),
      ...(link && { link }),
      ...image && { image },
      userId,

    };
    try {
      dispatch(editNews(newsUpdatedInfo));
    } finally {
      setIsFormChanged(false);
    }
  };
  useEffect(() => {
    if (image) {
      setIsFormChanged(true);
    }
    return () => setIsFormChanged(false);
  }, [form, image]);
  useEffect(() => {
    const fetchData = async () => {
      const { payload } = await dispatch(getNews());
      const news = payload.find((item) => item.id === parseInt(newsId, 10));
      form.setFieldsValue(news);
      setUserId(news.userId);
      setOldImage(news.image);
    };
    fetchData();
  }, [dispatch]);

  const { isLoading } = useAppSelector((state) => state.checkAuth);
  if (isLoading) {
    return <Loader />;
  }
  return (
    <ComponentLayout heading="Edit News">
      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
        onValuesChange={() => setIsFormChanged(true)}
      >
        <Form.Item name="title" rules={validationMessages.required}>
          <Input placeholder="Title" className="edit-form-input" />
        </Form.Item>
        <Form.Item name="newsContent" rules={validationMessages.required}>
          <Input.TextArea placeholder="News Content" className="edit-form-input" />
        </Form.Item>
        <Form.Item name="link">
          <Input placeholder="Link" className="edit-form-input" />
        </Form.Item>
        <div className="heading-nav">
          <div className="heading-icon" />
          <div className="title-heading">Change News Image</div>
        </div>
        <div className="ImageUploader">
          <ImageUploader submitImageToForm={(url) => setImage(url)} />
          <Image src={oldImage} alt="logo" className="profileimg" />
        </div>
        <Button className="form-button-dashboard" type="primary" htmlType="submit" disabled={!isFormChanged}>
          Edit Profile
        </Button>
      </Form>
    </ComponentLayout>
  );
}

export default EditNews;

import {
  USER_URL,
  LOGIN_URL,
  LOGOUT_URL,
  REGISTER_URL,
  GOOGLE_LOGIN_URL,
  FORGET_PASSWORD_URL,
  RESET_PASSWORD_URL,
  EDIT_PROFILE_URL,
  FINANCIAL_STATUS_URL,
  CHANGE_PASSWORD_URL,
  STATISTICS_DATA_URL,
  GET_USER_CONTENT_URL,
  PAYOUTS_URL,
  GET_CURRENT_WATCHED,
  CHOOSE_PAYMENT_METHOD_URL,
  GET_CONTENT_REPORT,
  GET_COMEDIAN_CONTENT,
  GET_NEWS,
  GET_COMEDIAN_LIST,
  ADD_COMEDIAN,
  ADD_COMEDIAN_CONTENT,
  GET_CURRENT_WATCHED_COMEDIAN,
  GET_COMEDIAN_CONTENT_REPORT,
  SHARE_CONTENT_BY_COMEDIAN,
  ACCOUNTANT_PAYOUTS_URL,
  GET_ACCOUNTANT_CONTENTS,
  CHANGE_USER_CONTENT_VAT_STATUS,
} from 'shared/constants/endpoints';
import { axiosCall } from '../utils';

export default {
  createUser: (userInfo) => axiosCall(REGISTER_URL, 'POST', userInfo),
  getUser: () => axiosCall(USER_URL, 'GET'),
  updateUser: (userInfo) => axiosCall(EDIT_PROFILE_URL, 'PATCH', userInfo),
  getStatistics: () => axiosCall(STATISTICS_DATA_URL, 'GET'),
  loginUser: (credentials) => axiosCall(LOGIN_URL, 'POST', credentials),
  logoutUser: () => axiosCall(LOGOUT_URL, 'GET'),
  googleLogin: (method, tokenId) => axiosCall(GOOGLE_LOGIN_URL(method), 'POST', { tokenId }),
  getUserContent: (data) => axiosCall(GET_USER_CONTENT_URL(data), 'GET'),
  getAccountantContents: (data) => axiosCall(GET_ACCOUNTANT_CONTENTS(data), 'GET'),

  forgetPassword: (email) => axiosCall(FORGET_PASSWORD_URL, 'POST', email),
  resetPassword: (email) => axiosCall(RESET_PASSWORD_URL, 'POST', email),

  createFinancialData: (data) => axiosCall(FINANCIAL_STATUS_URL, 'POST', data),
  getFinancialStatus: () => axiosCall(FINANCIAL_STATUS_URL, 'GET'),
  getContentReport: (contentId) => axiosCall(GET_CONTENT_REPORT(contentId), 'GET'),

  updateFinancialData: (data) => axiosCall(FINANCIAL_STATUS_URL, 'PATCH', data),
  changePassword: (data) => axiosCall(CHANGE_PASSWORD_URL, 'PATCH', data),
  getUserPayouts: () => axiosCall(PAYOUTS_URL, 'GET'),
  askForPayout: () => axiosCall(PAYOUTS_URL, 'PUT'),
  cancelPayout: () => axiosCall(PAYOUTS_URL, 'PATCH'),
  getCurentWatchedTime: (contentId) => axiosCall(GET_CURRENT_WATCHED(contentId), 'GET'),
  choosepaymentmethod: (payload) => axiosCall(CHOOSE_PAYMENT_METHOD_URL, 'PATCH', payload),
  getComedianContent: (data) => axiosCall(GET_COMEDIAN_CONTENT(data), 'GET'),
  getNews: () => axiosCall(GET_NEWS, 'GET'),
  getComedianList: () => axiosCall(GET_COMEDIAN_LIST, 'GET'),
  AddComedian: (data) => axiosCall(ADD_COMEDIAN, 'POST', data),
  addComedianContent: (data) => axiosCall(ADD_COMEDIAN_CONTENT, 'POST', data),
  getCurentWatchedTimeComedian: (contentId) => axiosCall(GET_CURRENT_WATCHED_COMEDIAN(contentId), 'GET'),
  getComedianContentReport: (contentId) => axiosCall(GET_COMEDIAN_CONTENT_REPORT(contentId), 'GET'),
  uploadContentByComedian: (data) => axiosCall(SHARE_CONTENT_BY_COMEDIAN, 'POST', data),
  askForAccountantPayout: () => axiosCall(ACCOUNTANT_PAYOUTS_URL, 'PUT'),
  getAccountantPayouts: () => axiosCall(ACCOUNTANT_PAYOUTS_URL, 'GET'),
  cancelAccountantPayout: () => axiosCall(ACCOUNTANT_PAYOUTS_URL, 'PATCH'),
  changeUserContentVatStatus: (data) => axiosCall(CHANGE_USER_CONTENT_VAT_STATUS, 'PATCH', data),

};

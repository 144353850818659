import { useEffect, useState } from 'react';
import { DetailedView } from 'components';
import { ComponentLayout } from 'layouts';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getAccountantContents } from 'store/user/thunk';

function AccountantVideoListPage() {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.checkAuth);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (user.id) {
      const fetchData = async () => {
        const { data } = await dispatch(getAccountantContents({ page: 1, limit: 100 })).unwrap();
        setRows(data?.userContents.rows || []);
      };
      fetchData();
    }
  }, [dispatch, user.id]);
  return (
    <ComponentLayout title="Video List" heading="Video List">
      <DetailedView rows={rows} listToSee="accountantVideoList" />
    </ComponentLayout>
  );
}

export default AccountantVideoListPage;

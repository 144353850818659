/* eslint-disable react/jsx-props-no-spreading */
import {
  Form, Button, AutoComplete, Input,
} from 'components/AntDesign';
import { useAppDispatch } from 'hooks';
import { ComponentLayout } from 'layouts';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import defaultPoster from 'assets/images/default-poster.png';
import {
  getApprovedList,
  updateContent,
} from 'store/admin/thunk';
import { getContentById } from 'store/content/thunk';
import { validationMessages } from 'utils';
import { Image } from 'shared/components';
import { UPDATE_LIST_URL } from 'shared/constants/endpoints';

export default function EditContentPage() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [content, setContents] = useState({});
  const [users, setUsers] = useState({});
  const [isFormChanged, setIsFormChanged] = useState(false);
  const dispatch = useAppDispatch();
  const { contentId } = useParams();
  const [value, setValue] = useState('');
  useEffect(() => {
    const fetchData = async () => {
      const payload = await dispatch(getApprovedList()).unwrap();
      setUsers(payload);
    };
    fetchData();
  }, []);
  const usersOptions = Object.values(users).map((option) => ({
    value: option.email,
    id: option.id,
  }));
  const currentEmail = usersOptions.find((option) => option.id === content?.userId)?.value;
  const onChange = (text) => {
    setValue(text);
  };

  const filterOption = {
    filterOption: (inputValue, option) => option?.value?.toUpperCase()
      .indexOf(inputValue.toUpperCase()) !== -1,
  };
  useEffect(() => {
    const fetchData = async () => {
      const data = await dispatch(getContentById(contentId)).unwrap();
      setContents(data);
      form.setFieldsValue({
        ...data,
        launchDate: data.launchDate?.split('T')[0],
        userId: currentEmail,
        otherRevenue: Object.values(data.otherRevenue || {}),
      });
    };
    fetchData();
    setIsFormChanged(false);
  }, [form, currentEmail]);

  const { title, permalink, publishDate } = content;
  const onFinish = async (values) => {
    const updates = {
      ...values,
      launchDate: values.launchDate?.split('T')[0],
      userId: usersOptions.find((option) => option.value === values.userId)?.id,
      id: contentId,
    };
    try {
      dispatch(updateContent(updates));
      navigate(UPDATE_LIST_URL);
    } finally {
      setIsFormChanged(false);
    }
  };

  return (
    <ComponentLayout title="Edit Content Page" heading={`Edit Content: ${title}`}>
      <div className="hero">
        <div className="form-container">
          <Form
            form={form}
            name="match-content-with-user"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={onFinish}
            autoComplete="off"
            className="my-form"
            layout="vertical"
            requiredMark={false}
            onValuesChange={() => setIsFormChanged(true)}

          >
            <div className="pairs">
              <Form.Item
                label="Select By User Email"
                name="userId"
                rules={validationMessages.userId}
              >
                <AutoComplete
                  size="large"
                  value={value}
                  options={usersOptions}
                  onChange={onChange}
                  placeholder="Search user email"
                  filterOption={filterOption}
                />
              </Form.Item>
              <Form.Item
                label="Launch Date"
                name="launchDate"
                rules={validationMessages.launchDate}
              >
                <Input size="large" placeholder="launch Date" type="date" />
              </Form.Item>

              <div className="couple">
                <div className="heading-nav">
                  <div className="heading-icon" />
                  <div className="title-heading">Update the costs</div>
                </div>
                <Form.Item
                  label="Filming Costs"
                  name="filmingCosts"
                  rules={validationMessages.filmingCosts}
                >
                  <Input placeholder="Filming cost" type="number" size="large" />
                </Form.Item>
                <Form.Item
                  label="Fee Paid"
                  name="feePaid"
                  rules={validationMessages.feePaid}
                >
                  <Input placeholder="Fee paid" type="number" size="large" />
                </Form.Item>
                <Form.Item
                  label="Advance"
                  name="advance"
                  rules={validationMessages.advance}
                >
                  <Input placeholder="Advance" type="number" size="large" />
                </Form.Item>
              </div>
            </div>

            <div className="reserved">
              <div className="heading-nav">
                <div className="heading-icon" />
                <div className="title-heading">
                  Update the Recovered Costs
                </div>
              </div>
              <Form.Item
                label="Recovered Costs"
                name="recoveredCosts"
                rules={validationMessages.recoveredCosts}
              >
                <Input placeholder="Recovered costs" type="number" size="large" />
              </Form.Item>
            </div>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className="form-button"
                disabled={!isFormChanged}
              >
                Update Content
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className="content-container">
          <Image src={permalink || defaultPoster} alt="Poster" className="poster" />
          <div className="info">
            <h1 className="contenttitle">{title}</h1>
            <h1 className="videoDate">{`Publish Date : ${publishDate?.slice(0, 10)}`}</h1>
          </div>
        </div>
      </div>
    </ComponentLayout>

  );
}

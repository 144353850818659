import { Button, Popconfirm, Table } from 'components/AntDesign';
import { useAppDispatch } from 'hooks';
import { ComponentLayout } from 'layouts';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EDIT_NEWS_ENDPOINT } from 'shared/constants/endpoints';
import { newsManagementColumns } from 'shared/objects/Users';
import { deleteNews } from 'store/admin/thunk';
import { getNews } from 'store/user/thunk';

function NewsManagement() {
  const [rows, setRows] = useState([]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // i want when i delete a news, the table will be updated
  const handleDelete = async (id) => {
    await dispatch(deleteNews(id));
    const { payload } = await dispatch(getNews());
    setRows(payload);
  };

  useEffect(() => {
    const fetchData = async () => {
      const { payload } = await dispatch(getNews());
      setRows(payload);
    };
    fetchData();
  }, [dispatch]);
  const newNewsManagementColumns = [
    ...newsManagementColumns,
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_text, news) => (
        <div className="table-cont">
          <Button
            onClick={() => navigate(EDIT_NEWS_ENDPOINT(news.id))}
            type="primary"
            className="table-btn"
          >
            Edit
          </Button>
          <Popconfirm title="Are you sure you want to delete this?" onConfirm={() => handleDelete(news)}>
            <Button
              type="primary"
              className="table-btn-block"
            >
              Delete
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <ComponentLayout title="News Management">
      <Table
        locale={{ emptyText: 'No news' }}
        columns={newNewsManagementColumns}
        dataSource={rows}
        pagination={{ pageSize: 5 }}
        scroll={{ x: 500 }}
      />
    </ComponentLayout>
  );
}
export default NewsManagement;
